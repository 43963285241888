import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "../main-handler";
import { IServerLocalCanonical } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { EMetadataNames } from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";
import { IVariable } from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";
import { IConfigureCanonical } from "@colmeia/core/src/shared-business-rules/bot/bot-model";


export interface ICanonicalPickerHandlerClientCallback {
    mapCanonicalToVariable(ns: IServerLocalCanonical): IVariable;
    onSelectCanonicalsCallback?(nss: IServerLocalCanonical[]): void;
    onUpdateCanonicalVariables?(nss: IVariable[]): void;
    onCanonicalsLoad?(nss: IServerLocalCanonical[]): void
    onCanonicalRemove?(idProperty: string): void
    isAllowingCanonicalRemove?(ns: IServerLocalCanonical): boolean

    onCanonicalsUpdate?(updatedCanonicals: IConfigureCanonical[]): void;
}

export interface ICanonicalPickerHandlerParemeters extends IComponentParameter {
    title?: string; // implement

    requiredGlobalCanonicals?: EMetadataNames[];

    canonicalIds: string[];
    canonicalsConfig?: IConfigureCanonical[];
    enableCanonicalsConfig?: boolean;

    clientCallback: ICanonicalPickerHandlerClientCallback;

    enableSyncWithCanonicalsService?: true;
    blockEdit?: true;
    maxCanonicals?: number;
    single?: true;
    enableCopy?: {
        onCopy(variable: IVariable): void;
    }
    borderless?: boolean; //<< Changes the border and the base design
}

export class CanonicalPickerHandler extends MainHandler {
    constructor(parameters: ICanonicalPickerHandlerParemeters) {
        super(parameters);
    }

    getComponentParameter(): ICanonicalPickerHandlerParemeters {
        return super.getComponentParameter() as ICanonicalPickerHandlerParemeters;
    }

}

