import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, ViewContainerRef } from '@angular/core';
import { getBotInitialEvent } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';
import { KAssetType, KBAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { getBotEventsByCharacteristic } from '@colmeia/core/src/shared-business-rules/bot/bot-client-functions';
import { EBotContentEvent, IContentBasicAsset, TContentAssetArray } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { eBotEventDB, getBotEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-event-config-db';
import { EBotEventType, IBotCommandText, IBotEvent, TBotEventArray } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { getMatchFunctionToEvents } from '@colmeia/core/src/shared-business-rules/bot/bot-other-client-functions';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { pickTranslations } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { botEventEnum } from "@colmeia/core/src/shared-business-rules/const-text/enums";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { botEnumTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/bot';
import { getEnumOption } from '@colmeia/core/src/shared-business-rules/enum-db';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { getUniqueStringID, isValidArrayAndRef, isValidRef, isValidTrimmedString, thisIsNotOneOfThat, typedClone, values } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { BotEventsHandler } from 'app/model/dashboard/bot/bot-events.handler';
import { IBotEventModalData, IOptionBotAssetArray } from 'app/model/dashboard/bot/dashboard-bot.model';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { LookupService } from 'app/services/lookup.service';
import { BotEventModalComponent } from '../bot-event-modal/bot-event-modal.component';
import { defaultMessagesMap, EEventMessageType, IEventMessageComposition } from './bot-events.constants';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-bot-events',
	templateUrl: './bot-events.component.html',
	styleUrls: ['./bot-events.component.scss']
})
export class BotEventsComponent extends RootComponent<
	'events' |
	'event' |
	'actions' |
	'add' |
	'addEvent' |
	'takeActionAfter' |
	'minutes' |
	'attemptsNumber' |
	'command' |
	'selectActionText' |
	'preCondition' |
	'selectPreConditionItem'
> implements OnInit {

	private eventsArr: EBotEventType[] = values(EBotEventType);

	eventNames: { [eventType: string]: string } = {};
	displayableColumns = ['events', 'actions'];

	@Input()
	public schemaVariables: TIVariablesArray = [];

	@Input() handler: BotEventsHandler;

	private eventTypes: IOptionBotAssetArray;
	public allBotEvents = values(EBotEventType);
    private ufnMap: Map<string, string> = new Map<string, string>();    

	constructor(
		private dialogSvc: ColmeiaDialogService,
		private cdr: ChangeDetectorRef,
		private viewContainerRef: ViewContainerRef,
        private lookupSvc: LookupService
	) {
		super({
			...pickTranslations(gTranslations.bot, [
				'events',
				'event',
				'addEvent',
				'takeActionAfter',
				'minutes',
				'attemptsNumber',
				'command',
				'selectActionText',
				'preCondition',
				'selectPreConditionItem'
			]),
			...pickTranslations(gTranslations.common, [
				'add',
				'actions'
			]),
			...botEnumTranslations
		});
	}

	ngOnInit() {
		this.loadEventsNames();
		this.resetEventTypes();

        if(this.isEmptyEvents()) {
            this.initializeDefaultEvents(); 
        }
	}

    private isEmptyEvents(): boolean {
        return this.handler.events?.length < 1;
    } 

    private initializeDefaultEvents(): void {
        if (!isValidArrayAndRef(this.eventTypes)) {
            console.log({ initializeDefaultEvents: 'Invalid event types' });
            return;
        }
    
        this.eventTypes.forEach(eventType => {
            if (this.handler.hasIncludedEventType((eventType?.type as EBotEventType))) {
                console.log({ skippedEventType: eventType.type });
                return;
            }
            const newEvent = this.eventsFactory('', eventType.type);
            this.handler.setEvent(newEvent);
        });

        this.resetEventTypes(); 
        this.cdr.markForCheck(); 
    }

    private eventsFactory(idAsset: string, type: EBotEventType | EBotActionType): IBotEvent { 
        const eventData: IBotEvent = {
            contentArray: this.buildContentArray(type) || [],
            eventAction: undefined,
            eventText: undefined,
            idAsset: idAsset || getUniqueStringID(10),
            preCondition: {
                idTreeVisited: undefined,
                checkType: undefined,
            },
            type: type as EBotEventType,
        };
        return eventData;
    }

    private buildContentArray(type: EBotEventType | EBotActionType): IContentBasicAsset[] | undefined {
        const foundDefaultMapItem: IEventMessageComposition[] = defaultMessagesMap.get(type);
        if(!isValidArrayAndRef(foundDefaultMapItem)) {
            console.log({buildContentArray: `Type ${type} dont have a corresponding defaultMapItem`}); //<< Log left
            return;
        }

        const contentArrayAux: IContentBasicAsset[] = [];
        foundDefaultMapItem.forEach(outerItem => {
            outerItem?.messages?.forEach(innerItem => {
                contentArrayAux.push(this.createContentArrayItemFactory(outerItem.eventMessageType, innerItem));
            })
        })

        return contentArrayAux;
    }

    private createContentArrayItemFactory(
        eventMessageType: EEventMessageType,
        message: string,
        // type: KBAssetType = KBAssetType.content
    ): IContentBasicAsset {
        return {
            botContentType: eventMessageType !== EEventMessageType.content
                ? eventMessageType === EEventMessageType.posContent
                    ? EBotContentEvent.posContent
                    : EBotContentEvent.preContent
                : undefined,
            content: message,
            idAsset: getUniqueStringID(10),
            type: KBAssetType.content,
            media: {
                idMedia: null,
                idMediaType: null,
                mymeType: null,
                size: null,
            },
            variablesTemplate: {
                variables: [],
                compiledTemplate: message,
                messageIfNoBind: "",
            },
        };
    }

    async ngAfterViewInit() { 
        await this.loadUfnNames();
        this.cdr.markForCheck();
    }

    private async resetUfnNames() {
        this.ufnMap = new Map<string, string>();
        await this.loadUfnNames();
        this.cdr.markForCheck();
    }

	private loadEventsNames() {
		this.eventsArr.forEach(eventType => {
			const eventName = getEnumOption(eventType, botEventEnum.fields, botEventEnum.idSerializable);
			this.eventNames[eventType] = eventName;
		});
	}

	private resetEventTypes() {
		this.eventTypes = [];

		const allEventTypes = getBotEventsByCharacteristic(getMatchFunctionToEvents(this.handler.branchType));
		allEventTypes.forEach(eventType => {
			const eventName = getEnumOption(eventType, botEventEnum.fields, botEventEnum.idSerializable);
			const eventConfig = getBotEvent(eventType);

			this.eventTypes.push({
				type: eventType,
				text: eventName,
				disabled: this.handler.hasIncludedEventType(eventType) && !eventConfig.canRepeatEvent,
			});
		});
	}

	get botEvents(): TBotEventArray {
		const assetEvents: KAssetType[] = Object.values(EBotEventType) as KAssetType[];
		return this.handler.events.filter(asset => assetEvents.includes(asset.type)).sort((a, b) => this.getEventName(a).localeCompare(this.getEventName(b)));
	}

	getEventName(botEvent: IBotEvent): string {
		return botEvent.type === EBotEventType.commandText
			? `${this.eventNames[botEvent.type]} (${(<IBotCommandText>botEvent).commandText})`
			: this.eventNames[botEvent.type];
	}

	async openEventModal(botEvent: IBotEvent, isEdit: boolean = false): Promise<void> {
		this.resetEventTypes();

		this.dialogSvc.open<BotEventModalComponent, IBotEventModalData>({
			title: this.translations[botEvent.type],
			componentRef: BotEventModalComponent,
			viewContainerRef: this.viewContainerRef,
			panelClass: "average-size",
			dataToComponent: {
				data: {
					isEdit,
					itemLevel: this.handler.branchType,
					botEvent: isValidRef(botEvent) ? typedClone(botEvent) : undefined,
					schemaVariables: this.schemaVariables,
					idKB: this.handler.idKB,
					eventTypes: this.eventTypes,
					onSaveEventCallback: async (botEvent: IBotEvent) => {
						this.handler.setEvent(botEvent);
						this.resetEventTypes();
                        await this.resetUfnNames();
                        // this.cdr.markForCheck();
					}
				},
			},
		});
	}

	initBotEvent(type: EBotEventType) {
		return getBotInitialEvent({ type });
	}

	removeEvent(event: IBotEvent): void {
		this.handler.removeEvent(event);
		this.resetEventTypes();
		this.cdr.markForCheck();
	}

	public get availableEvents(): (EBotActionType | EBotEventType)[] {
		const allCurrentEvents = this.botEvents.map(e => e.type) as (EBotActionType | EBotEventType)[];
		const availableBotEvents = this.eventTypes.map(et => et.type)
			.filter(eventType => eBotEventDB[eventType].canRepeatEvent || !allCurrentEvents.includes(eventType));

		return availableBotEvents;
	}

    public doesEventHaveUfn(event: IBotEvent): boolean {
        return isValidTrimmedString(event?.idFunction) || isValidTrimmedString(event?.idFunctionBefore);
    }

    public getEventUfnName(event: IBotEvent): string {
        const eventUfnId: string | undefined = !!event?.idFunction
            ? event?.idFunction
            : event?.idFunctionBefore;
        return this.ufnMap.get(eventUfnId) || '';
    }

    private async loadUfnNames() {
        if(!isValidArrayAndRef(this.botEvents)){
            return;
        }
        for(const event of this.botEvents) { 
            const eventUfnId: string | undefined = !!event?.idFunction
                ? event?.idFunction
                : event?.idFunctionBefore;
            await this.setUfnName(eventUfnId);
        }
    }

    private async setUfnName(ufnId: string): Promise<void> {
        if(!isValidTrimmedString(ufnId)) {
            // console.log({getUfnName: 'Invalid id'}); //<< Log left
            return;
        }

        if(!!this.ufnMap.get(ufnId)) {
            console.log({getUfnName: 'uFn name already gathered'}); //<< Log left
            return;
        } else {
            const ufnName: string = (await this.lookupSvc.getNS(ufnId))?.nName;
            if(!isValidTrimmedString(ufnName)) {
                console.log({getUfnName: 'Not valid nName'}); //<< Log left
                return;
            }
            this.ufnMap.set(ufnId, ufnName);
        }
    }
    
    public doesEventHaveExibitionCondition(event: IBotEvent): boolean {
        // console.log({doesEventHaveExibitionCondition: event}); //<< Log left
        return event?.contentArray?.some(content => content?.isConditionalContent);
    }

    public getContentsArray(event: IBotEvent): TContentAssetArray {
        return event?.contentArray
            ?.filter(cnt => isValidTrimmedString(cnt?.content));
    }

    public doesABotEventHaveTextContent(event: IBotEvent): boolean {
        return event?.contentArray?.some(content => isValidTrimmedString(content?.content));
    }

    public doesAContentHaveCondition(content: IContentBasicAsset): boolean {
        return content?.isConditionalContent;
    }

    public get hideBorderClass() { //<< Important
        return this.handler?.borderless
            ? 'hide-borders'
            : '';
    }

    public get shouldRenderBorderless() {
        return this.hideBorderClass !== '';
    }
}
