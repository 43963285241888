import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { type ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import {
    IAdvancedTemplateAction,
    ITemplateTextWithVariables,
} from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import {
    IGenericTemplateParameter,
    IGenericNSTemplateServer,
    TVariablesExamples,
} from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { IRCSAdvancesTemplateAction, RCS } from "./rcs-interface";
import { IContentBasicAsset } from "../bot/bot-content-model";

export enum ERCSMessageType {
    /**
     * RCS parecido com SMS limite de 160 chars, etc
     */
    basic = "basic",
    /**
     * RCS text, image, video, pdf text, image, video, pdf, richCard, carousel ou suggestion
     */
    single = "single",
    /**
     * Bot interaction
     */
    conversational = "conversational",
}

export enum ERCSContentType {
    text = "text",
    image = "image",
    video = "video",
    pdf = "pdf",
    /**
     * consegue enviar video imagem pdf numa mensagem so ate 4 sugestions
     */
    richCard = "richCard",
    /**
     * permite carregar multiplas imagens
     */
    carousel = "carousel",
    /**
     * botoes de action
     */
    suggestion = "suggestion",
}

export enum ERCSActionsButtonType {
    openUrl = "openUrl",
    call = "call",
    reply = "reply",
}

export interface IRCSActionButton {
    buttonType: ERCSActionsButtonType;
    title: string;
    value: string;
}

export interface IRCSActionsButton {
    description: string;
    buttons: Array<IRCSActionButton>;
}

export interface IRCSRichCard {
    title: string;
    description: string;
    fileUrl: string;
    actionsButton?: IRCSActionsButton;
}

export interface IRCSCarrousel {
    title: string;
    description: string;
    forceRefresh?: boolean;
    fileUrl: string;
    actionsButton?: IRCSActionsButton;
}

export interface IRCSProviderBodyContent {
    title: string;
    titleWithVariables: ITemplateTextWithVariables;
    description?: string;
    rcsBodyType: ERCSContentType;
    text?: string;
    basicMessage: ITemplateTextWithVariables;
    fileURL?: string;
    content: IContentBasicAsset;
    advancedOption?: IRCSAdvancesTemplateAction;
}

export interface IRCSTemplateParameters {
    clientCallback: Object;
    template: IRCSTemplateParameter;
}

export interface IRCSTemplateConfig {
    title: string;
    compiledTitleWithProperties: string;
    /**
     * cabecalho da mensagem (mídia)
     */
    header: {
        content: IContentBasicAsset;
        format: RCS.Message.Template.Structure.Format;
    };
    /**
     * corpo da mensagem, titulo (card),
     */
    content: IRCSProviderBodyContent;
    compiledTemplateWithProperties: string;
    /**
     * botoes de acao
     */
    advancedOption?: IRCSAdvancesTemplateAction;
};


export interface IRCSTemplateParameter extends IGenericTemplateParameter {
    channelType: EDelivery360Action.Delivery360RCS;
    rcsMessageType: ERCSMessageType;
    templateCategory: RCS.Template.Category;
    config: Array<IRCSTemplateConfig>;
    template?: string; //same as content.text
    /**
     * mensagem enviada via sms caso nao consiga enviar RCS
    */
    fallbackMessage: string;
    compiledFallbackTemplateWithProperties: string;
    fallbackTextWithVariables: ITemplateTextWithVariables;

    // TODO: verificar se é necessário
    variablesExamples: TVariablesExamples;
    language: RCS.Template.Language;
}
export interface INSRCSTemplateServer extends IGenericNSTemplateServer {
    nsType: ENonSerializableObjectType.channelTemplate;
    parameter: IRCSTemplateParameter;
}
