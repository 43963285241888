import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { IConfigureCanonical } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { IServerLocalCanonical, IServerLocalCanonicalArray } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { IVariable } from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isInvalid, isValidArray, isValidFunction, isValidRef } from "@colmeia/core/src/tools/utility";
import { CanonicalPickerHandler, ICanonicalPickerHandlerClientCallback } from "app/handlers/canonical/canonical-picker.handler";
import { MainHandler } from "app/handlers/main-handler";
import { INSPickerHandlerParameter, NSPickerHandler } from "app/handlers/ns-picker/ns-picker.handler";
import { AttendantResourcesService } from "app/services/attendant-rources.service";
import { CanonicalService } from "app/services/canonical.service";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { ColmeiaDialogService } from "app/services/dialog/dialog.service";
import { LookupService } from "app/services/lookup.service";
import { DynamicComponentHandler } from "../dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler";
import { DynamicDialogComponent } from "../dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component";
import { RootComponent } from "../foundation/root/root.component";
import { CanonicalPickerEditCanonicalConfigHandler } from "./canonical-picker-edit-canonical-config/canonical-picker-edit-canonical-config.handler";
import { ObjectUtils } from "@colmeia/core/src/tools/utility/objects/object-utils";

@Component({
    selector: "app-canonical-picker",
    templateUrl: "./canonical-picker.component.html",
    styleUrls: ["./canonical-picker.component.scss"],
})
export class CanonicalPickerComponent extends RootComponent<
    | 'variables'
    | 'variable'
> implements OnInit {

    _handler: CanonicalPickerHandler;
    possibleTemplateVariables: IVariable[];
    cache: Map<string, IServerLocalCanonical> = new Map();
    public nsSelectHandler: NSPickerHandler;

    @Input() set handler(value: CanonicalPickerHandler) {
        this._handler = value;
        this.init()
    }

    get handler(): CanonicalPickerHandler {
        return this._handler;
    }

    constructor(
        private dialogSvc: ColmeiaDialogService,
        private canonicalSvc: CanonicalService,
        private lookupSvc: LookupService,
        private dashboardSvc: DashBoardService,
		private attendantResourcesSvc: AttendantResourcesService,
    ) {
        super({
            variables: gTranslations.socialMedia.meanings,
            variable: gTranslations.socialMedia.meaning
        });
    }

    public get enableCopy(): boolean {
        return isValidRef(this.parameters.enableCopy)
    }


    public copyVariable(variable: IVariable): void {
        this.parameters.enableCopy?.onCopy?.(variable);
    }

    public ngOnInit() {}

    private execCallbackIfPossible<T extends keyof ICanonicalPickerHandlerClientCallback>(functionName: T, ...params: Parameters<ICanonicalPickerHandlerClientCallback[T]>) {
        const callbackFunction = this.parameters.clientCallback[functionName];

        if(isValidFunction(callbackFunction)){
            callbackFunction.apply(this, params as any);
        }
    }

    public get allowEdit(): boolean {
        return !this.parameters.blockEdit;
    }

    public async getEntities(): Promise<IServerLocalCanonical[]> {
        if (isValidArray(this.parameters.requiredGlobalCanonicals)) {
            await this.addCanonicalsByGlobal();
        }
        const toFetch: string[] = this.parameters.canonicalIds.filter((id: string) => !(this.cache.has(id)));
        if (isValidArray(toFetch)) {
            await this.fetchCanonicalsByIds(toFetch);
        }

        return this.parameters.canonicalIds.map((id: string) => this.cache.get(id));
    }

    public async fetchCanonicalsByIds(ids: string[]): Promise<void> {
        const fromFetch: IServerLocalCanonical[] = (await this.lookupSvc.getBatchNonSerializables<IServerLocalCanonical[]>(ids));
        for (let canonical of fromFetch) {
            this.cache.set(canonical.idNS, canonical);
        }
    }

    public async addCanonicalsByGlobal(): Promise<void> {
        const localCanonicals: IServerLocalCanonicalArray = await this.canonicalSvc.getLocalByGlobalCanonicals(
            this.parameters.requiredGlobalCanonicals);
        for (let canonical of localCanonicals) {
            this.cache.set(canonical.idNS, canonical);
        }
        const ids: string[] = [...new Set([...this.parameters.canonicalIds, ...localCanonicals.map((canonical: IServerLocalCanonical) => canonical.idNS)])];
        this.parameters.canonicalIds.splice(0);
        this.parameters.canonicalIds.push(...ids);
    }

    public async init(): Promise<void> {
        if (this.parameters.enableCanonicalsConfig)
            this.initMapIdCanonicalToConfig()
        ;
        await this.updatePossibleTemplateVariables();
        this.execCallbackIfPossible("onCanonicalsLoad", [...this.cache.values()]);
        //
    }


    public initMapIdCanonicalToConfig(): void {
        for (const config of this.parameters.canonicalsConfig) {
            this.mapIdCanonicalToConfig.set(config.idCanonical, config)
        }
    }

    public canonicalTrackBy(_idx: number, item: IVariable) {
        return ObjectUtils.stringifyObjectWithSortingKeys(item);
    }

    public mapIdCanonicalToConfig: Map<string, IConfigureCanonical> = new Map();
    public async updatePossibleTemplateVariables(): Promise<void> {
        const entitites: IServerLocalCanonical[] = (await this.getEntities()).filter(entity => isValidRef(entity))
        this.possibleTemplateVariables = entitites
            .map((entity: IServerLocalCanonical) => this.parameters.clientCallback.mapCanonicalToVariable(entity));

        if (isValidFunction(this.parameters.clientCallback.onUpdateCanonicalVariables))
            this.parameters.clientCallback.onUpdateCanonicalVariables(this.possibleTemplateVariables)
        ;

        if (isValidRef(this.parameters.enableSyncWithCanonicalsService))
            this.canonicalSvc.setCanonicals(entitites, this.parameters.canonicalsConfig)
        ;

        if (this.parameters.enableCanonicalsConfig) {
            this.syncCanonicalsConfig();
        }

        this.initializeNSPickerHandler();
    }

    public mapCanonicalIdToHandler: Map<string, CanonicalPickerEditCanonicalConfigHandler> = new Map();
    public getCanonicalConfigHandler(idNS: string): CanonicalPickerEditCanonicalConfigHandler {
        if (!this.mapCanonicalIdToHandler.has(idNS)) {
            const handler: CanonicalPickerEditCanonicalConfigHandler = CanonicalPickerEditCanonicalConfigHandler.factory({ config: this.mapIdCanonicalToConfig.get(idNS), clientCallback: {}, });
            handler.slave = this;
            this.mapCanonicalIdToHandler.set(idNS, handler);
        }

        return this.mapCanonicalIdToHandler.get(idNS);
    }

    public waitDialog<T>(dialogRef: MatDialogRef<T>): Promise<void> {
        return new Promise((resolve) =>
            dialogRef.beforeClosed().subscribe(() => {
                resolve();
            })
        );
    }
    public dynamicDialog<Component, Handler>(
        component: Component,
        handler: Handler,
        setDialogRef?: (ref: MatDialogRef<any>) => void,
    ): Promise<void> {
        const ref = this.dialogSvc.open<
            DynamicDialogComponent,
            DynamicComponentHandler
        >({
            componentRef: DynamicDialogComponent,
            hideHeader: true,
            dataToComponent: {
                data: DynamicComponentHandler.factory({
                    component,
                    handler: (handler as unknown) as MainHandler,
                }),
            },
        })
        if (isValidFunction(setDialogRef)) setDialogRef(ref);
        return this.waitDialog(ref);
    }

    public syncCanonicalsConfig(): void {
        const map: Map<string, IConfigureCanonical> = new Map();
        for (const idCanonical of this.parameters.canonicalIds) {
            const config: IConfigureCanonical = this.mapIdCanonicalToConfig.has(idCanonical)
                ? this.mapIdCanonicalToConfig.get(idCanonical)
                : ({ idCanonical, isSafe: false })
            ;
            map.set(idCanonical, config);
        }

        this.parameters.canonicalsConfig.splice(0);
        this.parameters.canonicalsConfig.push(...map.values());
        this.mapIdCanonicalToConfig = map;

        if (this.parameters.clientCallback.onCanonicalsUpdate) {
            this.parameters.clientCallback.onCanonicalsUpdate(Array.from(map.values()));
        }
    }

    get parameters() { return this.handler.getComponentParameter() };

    public async removeVariable(idProperty: string): Promise<void> {
        if (isValidFunction(this.parameters.clientCallback.isAllowingCanonicalRemove)) {
            const isAllowingCanonicalRemove: boolean = this.parameters.clientCallback.isAllowingCanonicalRemove(this.cache.get(idProperty));
            if (!isAllowingCanonicalRemove) return;
        }

        const canonical = this.cache.get(idProperty)

		const deleteTitle = `Remover significado${canonical?.nName ? ": '" + canonical.nName + "'" : ''}?`;
		const confirmedDeletion = await this.attendantResourcesSvc.confirmDeletion(deleteTitle);

        if (confirmedDeletion) {
            const index: number = this.parameters.canonicalIds.findIndex(id => id === idProperty);
            if (index !== -1) this.parameters.canonicalIds.splice(index, 1);
    
            await this.updatePossibleTemplateVariables();
    
            if(!this.mapIdCanonicalToConfig.has(idProperty)) {
                this.execCallbackIfPossible("onCanonicalRemove", idProperty);
            }
        }
    }

    public onSaveCallback(nss: IServerLocalCanonical[]): void {
        if(!isValidArray(nss)) return;

        this.parameters.canonicalIds.splice(0, this.parameters.canonicalIds.length, ...nss.map((ns: IServerLocalCanonical) => ns.idNS));
        this.updatePossibleTemplateVariables();

        this.execCallbackIfPossible("onSelectCanonicalsCallback", nss);
    }

    public getCanonicalById(id: string): IServerLocalCanonical {
        return this.cache.get(id);
    }
    public onClearCallback(ns: IServerLocalCanonical) {
        if(this.parameters.requiredGlobalCanonicals?.includes(ns.globalCanonical)) return;

        this.removeVariable(ns.idNS);
    }

    public openFieldAdder(): void {
        this.nsSelectHandler.onOpenModal();
    }

    public canRemove(idProperty: string){
        if(!isValidArray(this.parameters.requiredGlobalCanonicals) || !this.cache.has(idProperty)) return true;

        const canonical = this.cache.get(idProperty);

        return !this.parameters.requiredGlobalCanonicals.includes(canonical.globalCanonical);
    }

    private initializeNSPickerHandler(): void {
        const isSingle: boolean = this._handler.getComponentParameter().single;

        const nsPickerParameter: INSPickerHandlerParameter = {
            title: "Escolha um significado",
            nsType: ENonSerializableObjectType.canonical,
            clientCallback: this,
            idParent: null,
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            nonSerializables: this.parameters.canonicalIds.map((id: string) => this.getCanonicalById(id)),
            maxSelections: isSingle
                ? 1
                : Number.MAX_SAFE_INTEGER,
        };

        this.nsSelectHandler = new NSPickerHandler(nsPickerParameter);
    }

    public disableAddMoreVariables(){
        return this.parameters.canonicalIds.length === this.parameters.maxCanonicals;
    }

    public get hideBorderClass() {
        return this.parameters.borderless
            ? 'hide-borders'
            : '';
    }

    public get shouldHideBorder() {
        return this.hideBorderClass !== '';
    }
}
