import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IAttendanceCalendarServer } from '@colmeia/core/src/shared-business-rules/attendance-island/ns-sheduler';
import { initialEvent, isValidContentArray } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';
import { IBotActionAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-action-model';
import {
    IBasicAsset,
    KBAssetType,
    KBAssetTypeClientOnly,
    TBasicAssetArray,
    TKAssetTypeArray
} from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import {
    EBotContentEvent,
    IContentBasicAsset,
    TContentAssetArray
} from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import {
    eBotEventDB,
    geEventDB,
    getBotEvent,
    IBotEventTypeConfig,
    TEventDB
} from '@colmeia/core/src/shared-business-rules/bot/bot-event-config-db';
import {
    EBotEventType,
    ECustomerIdleLastCallType,
    IAccuracyData,
    IAgentDoesNotAnwer,
    IBasicConfirmationEvent,
    IBotCommandText,
    IBotEvent,
    IConfigurableConfirmationEvent,
    ICustomerDoesNotAnswer,
    IExpireEvent,
    IInvalidOption,
    IIsHelpfullAIInsight,
    IKBIntentName,
    IReuseFormEvent,
    ISmartFlowTRFInjection,
    ITransactionToTransactionConfirmation,
    TQueueEventSubtype
} from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { EEvalulatorType } from '@colmeia/core/src/shared-business-rules/bot/bot-interfaces';
import { EOnConfirmationFields, EventActionMap } from '@colmeia/core/src/shared-business-rules/bot/event-confirmation-db';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { getEmptyActionOnErrorsConfig } from '@colmeia/core/src/shared-business-rules/BPM/bpm-functions';
import { ICampaignServer, ICampaingActionHeader } from '@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model';
import { ECampaignExecutionType } from '@colmeia/core/src/shared-business-rules/campaigns/capaing-exec-interfaces';
import { IServerLocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { pickTranslations } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { assetScreenElements } from '@colmeia/core/src/shared-business-rules/const-text/asset-text';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { botEnumTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/bot';
import { IMLCLUIntent } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { IKBGetInformationResponse } from '@colmeia/core/src/shared-business-rules/knowledge-base/kn-req-resp';
import { emptyAsset } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { nsToVariable } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import {
    ENonSerializableObjectType,
    INonSerializable
} from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { hashAttendanceEvents } from '@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/custom-events';
import { IUserFunctionModelServer } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { hourToMS, minToMS, msToHour, msToMin } from '@colmeia/core/src/time/time-utl';
import { genericTypedSuggestions, nonNullable } from '@colmeia/core/src/tools/type-utils';
import {
    getUniqueStringID,
    isInvalid,
    isInvalidArray,

    isValidAndEqual,
    isValidArray, isValidArrayAndRef, isValidRef,


    isValidTrimmedString,


    typedClone
} from '@colmeia/core/src/tools/utility';
import { FindKeysWithValueOf } from '@colmeia/core/src/tools/utility-types';
import { TCampaignActionPickerParameters } from 'app/components/dashboard/campaign-action-picker-dialog/campaign-action-picker-dialog.component';
import { ColmeiaSliderComponent } from 'app/components/dashboard/colmeia-slider/colmeia-slider.component';
import { IActionOnErrorHandler } from 'app/components/dashboard/new-condition-editor/action-on-error-editor/action-on-error-editor.model';
import { ISingleContentAssetAdderHandler } from 'app/components/dashboard/single-content-asset-editor/single-content-asset-editor.handler';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import {
    EEnumPickerMode,
    EnumPickerHandler,
    IEnumPickerClientCallback,
    IEnumPickerHandlerParameter
} from 'app/components/foundation/enum-picker/enum-picker.handler';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ETimestmapPickerElements, TTimestampElementsConfigs } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { ConfirmationTextService } from 'app/confirmation-text.service';
import { IInputNumberClientCallback, InputNumberHandler } from 'app/handlers/input-number.handler';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import {
    IBotEventModalData,
    initBotElementWithRenderOptions,
    initialAction,
    IOptionBotAsset,
    IOptionBotAssetArray
} from 'app/model/dashboard/bot/dashboard-bot.model';
import { CanonicalService } from 'app/services/canonical.service';
import { DashboardBotsService } from 'app/services/dashboard/dashboard-bots.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { KnowledgeBaseService } from 'app/services/knowledge-base.service';
import { LookupService } from 'app/services/lookup.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { GenericNonSerializableService } from "../../../../../services/generic-ns.service";
import { RequestBuilderServices } from "../../../../../services/request-builder.services";
import { RoutingService } from "../../../../../services/routing.service";
import { ServerCommunicationService } from "../../../../../services/server-communication.service";
import { SessionService } from "../../../../../services/session.service";
import { IBotActionHandler } from "../../../bot-action-editor/bot-action-editor.component";
import { CmRenderOptionsHandler } from '../bot-edit/bot-configuration/cm-render-options/cm-render-options.handler';
import { IBotEventExecuteCampaignHandler } from '../bot-execute-campaign/bot-execute-campaign.component';
import { IConfigurableConfirmationEventHandler } from './on-confirmation-event/on-confirmation-event.handler';
import { EDashboardIdentifier } from 'app/model/dashboard/dash-identifier';

interface IPreCondition {
    type: EEvalulatorType;
    text: string;
}

interface ILastCallHandlers {
    content?: IAssetAdderHandler;
    campaign?: IBotEventExecuteCampaignHandler;
    menuTitle?: ISingleContentAssetAdderHandler;
    actionHandler?: IBotActionHandler;

}

@Component({
    selector: 'app-bot-event-modal',
    templateUrl: './bot-event-modal.component.html',
    styleUrls: ['./bot-event-modal.component.scss']
})
export class BotEventModalComponent extends RootComponent<
    'bots'
    | 'save'
    | 'cancel'
    | 'config'
    | 'image'
    | 'name'
    | 'incrementBy'
    | 'letters'
    | 'numbers'
    | 'primaryBot'
    | 'configAction'
    | 'subject'
    | 'optionAction'
    | 'message'
    | 'waitSeconds'
    | 'create'
    | 'remove'
    | 'selectMetadata'
    | 'selectRoot'
    | 'selectNode'
    | 'loadError'
    | 'noBots'
    | 'events'
    | 'event'
    | 'actions'
    | 'add'
    | 'addEvent'
    | 'takeActionAfter'
    | 'minutes'
    | 'attemptsNumber'
    | 'command'
    | 'selectActionText'
    | 'preCondition'
    | 'selectPreConditionItem'
    | 'title'
    | 'ignoreAccuracyUntil'
    | 'ignoredMessage'
    | 'confirmAccuracyUntil'
    | 'confirmMessage'
    | 'fieldRequired'
    | 'createBot'
    | 'negativeText'
    | 'positiveText'
> implements OnInit, IEnumPickerClientCallback<EBotEventType> {
    public readonly defaultAccuracyMin: number = 0;
    public readonly defaultAccuracyMax: number = 100;
    public readonly accuracyMargin: number = 1;
    public readonly insuficientMaxValue: number = 70;
    public readonly inconclusiveMaxValue: number = 90;

    private validateCommandReg: RegExp = new RegExp(/^[a-zA-Z]+$/);
    private handler: IBotEventModalData;
    schemaVariables: TIVariablesArray;
    preConditionSelectHandler: NSPickerHandler;
    handlerAssets: IAssetAdderHandler;
    handlerPreAssets: IAssetAdderHandler;
    handlerPosAssets: IAssetAdderHandler;
    preConditions: IPreCondition[] = [];
    eventActionHandler: IBotActionHandler;
    enumPickerHandler: EnumPickerHandler<string>;
    botExecuteCampaignHandler!: IBotEventExecuteCampaignHandler;
    userFunctionPickerHandler!: NSPickerHandler<ENonSerializableObjectType.userFunction>;
    calendarPickerHandler!: NSPickerHandler<ENonSerializableObjectType.attendanceCalendar>;
    userFunctionBeforePickerHandler!: NSPickerHandler<ENonSerializableObjectType.userFunction>;
    private generic: GenericNonSerializableService;

    public smartFlowRTFHeaderPicker: NSPickerHandler;

    @ViewChild(ColmeiaSliderComponent, { static: false }) noUISlider: ColmeiaSliderComponent;

    cmRenderOptionsHandler: CmRenderOptionsHandler;

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public dataToComponent: IColmeiaDialogComponentData<IBotEventModalData>,
        private dialogRef: MatDialogRef<BotEventModalComponent>,
        private multimediaSvc: MultimediaService,
        private dashBotSvc: DashboardBotsService,
        private dashboardSvc: DashBoardService,
        private api: ServerCommunicationService,
        private session: SessionService,
        private routeSvc: RoutingService,
        private rbs: RequestBuilderServices,
        private kbSvc: KnowledgeBaseService,
        private confirmationTextSvc: ConfirmationTextService,
        private snackBarSvc: MatSnackBar,
        private canonicalSvc: CanonicalService,
        private lookupSvc: LookupService,
        private snackMsgSvc: SnackMessageService,
    ) {
        super({
            ...pickTranslations(gTranslations.common, [
                'save',
                'cancel',
                'config',
                'image',
                'name',
                'subject',
                'message',
                'create',
                'remove',
                'title',
                'fieldRequired',
                'actions',
                'add',
            ]),
            ...pickTranslations(gTranslations.bot, [
                'bots',
                'incrementBy',
                'letters',
                'numbers',
                'primaryBot',
                'configAction',
                'optionAction',
                'waitSeconds',
                'selectMetadata',
                'selectRoot',
                'selectNode',
                'loadError',
                'noBots',
                'events',
                'event',
                'addEvent',
                'takeActionAfter',
                'minutes',
                'attemptsNumber',
                'command',
                'selectActionText',
                'preCondition',
                'selectPreConditionItem',
                'ignoreAccuracyUntil',
                'ignoredMessage',
                'confirmAccuracyUntil',
                'confirmMessage',
                'createBot',
                'sendExpireMessagesInOrder'
            ]),
            ...pickTranslations(gTranslations.fragments, [
                'negativeText',
                'positiveText',
            ]),
            ...botEnumTranslations
        });
        this.generic = new GenericNonSerializableService(null, null, {
            api,
            session,
            routeSvc,
            rbs
        });

        this.handler = dataToComponent.getParamsToChildComponent();

        this.schemaVariables = this.handler.schemaVariables;
        if (isInvalid(this.handler.botEvent)) {
            this.handler.botEvent = {
                ...typedClone<IBotEvent>(initialEvent),
                idAsset: getUniqueStringID(10),
            };
        } else {
            this.setHandlerAssets();
        }
    }

    insuficientAccuracyInputNumber: InputNumberHandler;
    inconclusiveAccuracyInputNumber: InputNumberHandler;

    get customerExpire() {
        return (this.event as ICustomerDoesNotAnswer);
    }

    hasAdvancedMenuConfig() {
        return this.getConfigDB().showAdvancedMenu;
    }
    
    get accuracyConfirmAccuracyUntil() {
        return (<IAccuracyData>this.event).inconclusiveAccuracyUntil;
    }
    set accuracyConfirmAccuracyUntil(val) {
        (<IAccuracyData>this.event).inconclusiveAccuracyUntil = val;
    }

    get accuracyIgnoreAccuracyUntil() {
        return (<IAccuracyData>this.event).insuficientAccuracyUntil;
    }
    set accuracyIgnoreAccuracyUntil(val) {
        (<IAccuracyData>this.event).insuficientAccuracyUntil = val;
    }

    get nameEventText(): string {
        const id: number = this.getConfigDB().idFieldVisualElement;
        const ser: Serializable = Serializable.staticFactory(assetScreenElements.idSerializable);
        return ser.getSerializableText(id);
    }

    getConfigDB(): IBotEventTypeConfig {
        return eBotEventDB[this.event.type];
    }

    ngOnInit() {
        this.consistency();
        this.initCommonHandlers();
        this.generateEnumHandler();
        if (isValidRef(this.event.type) && this.hasPreCondition()) {
            this.initPreCondition();
        }
        if (isValidRef(this.event.type)) {
            this.initEventActionHandler();
        }
        if (isValidRef(this.event.type) && this.canShowPrePosAssets) {
            this.handlerPreAssets = this.initAssetsPrePos(EBotContentEvent.preContent);
            this.handlerPosAssets = this.initAssetsPrePos(EBotContentEvent.posContent);
        }

        const config = this.getConfigDB();

        if (config.showSmartFlowRTFHeaderPicker) {
            this.initSmartFlowRTFHeaderPicker();
        }

        if (config.allowExecuteCampaign) {
            this.initBotFireCampaignHandler();
        }

        if (config.allowExecUserFunctionBefore) {
            this.initUserFunctionBeforeHandler();
        }

        if (config.allowExecUserFunction) {
            this.initUserFunctionHandler();
        }

        if (config.showCalendarPicker) {
            this.initCalendarPickerHandler();
        }

        if (config.showAdvancedMenu) {
            this.initAdvancedMenuHandler();
        }

        if(config.showLastCall) {
            this.initLastCallHandlers();
        }
    }

    private initAdvancedMenuHandler() {
        if (!this.event.renderOptions) {
            this.event.renderOptions = initBotElementWithRenderOptions();
        }

        this.cmRenderOptionsHandler = new CmRenderOptionsHandler({
            element: this.event.renderOptions,
            clientCallback: {
                onConsistency: (element) => {
                }
            },
            shouldShowMultipleChoiceAdvancedTitle: false,
            ignoredRenderOptions: []
        });
    }

    private initCalendarPickerHandler() {
        this.calendarPickerHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.attendanceCalendar,
            title: "Calendário",
            useDemandedTag: false,
            selectedId: this.event.idCalendar,
            clientCallback: {
                onSaveNSCallback: (ns: IAttendanceCalendarServer) => {
                    this.event.idCalendar = ns?.idNS;
                }
            }
        });
    }

    private initUserFunctionBeforeHandler() {
        this.userFunctionBeforePickerHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.userFunction,
            title: "Função",
            useDemandedTag: false,
            selectedId: this.event.idFunctionBefore,
            clientCallback: {
                onSaveNSCallback: (ns: IUserFunctionModelServer) => {
                    this.event.idFunctionBefore = ns?.idNS;
                }
            }
        })
    }

    private initUserFunctionHandler() {
        this.userFunctionPickerHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.userFunction,
            title: "Função",
            useDemandedTag: false,
            selectedId: this.event.idFunction,
            clientCallback: {
                onSaveNSCallback: (ns: IUserFunctionModelServer) => {
                    this.event.idFunction = ns?.idNS;
                }
            }
        })
    }

    allowExecuteCampaign(): boolean {
        return this.getConfigDB().allowExecuteCampaign;
    }

    private initBotFireCampaignHandler() {
        if (!isValidRef(this.event.camp)) return;

        this.botExecuteCampaignHandler = {
            config: this.event.camp,
        }
    }

    get botFireCampaign(): boolean {
        return isValidRef(this.event.camp);
    }

    set botFireCampaign(value: boolean) {
        if (!value) {
            this.event.camp = undefined;
            this.botExecuteCampaignHandler = undefined;
            return;
        }

        this.event.camp = {
            idElement: undefined,
            type: ECampaignExecutionType.traditional,
        }

        this.initBotFireCampaignHandler();
    }

    private initSmartFlowRTFHeaderPicker() {
        this.smartFlowRTFHeaderPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: "SmartFlow RTF Injection Header",
            useDemandedTag: undefined,
            nsType: ENonSerializableObjectType.smartFlowRTFParamHeader,
            clientCallback: {
                onSaveNSCallback: (ns) => {
                    this.smartFlowRTFInjection.smartFlowRTFHeaderId = ns?.idNS;
                }
            },
            selectedId: this.smartFlowRTFInjection.smartFlowRTFHeaderId
        })
    }

    private consistency() {
        if (this.canShowEventAction() && !isValidRef(this.handler.botEvent.eventAction)) {
            this.handler.botEvent.eventAction = {
                ...typedClone<IBotActionAsset>(initialAction),
                idAsset: getUniqueStringID(10),
            };
            this.initEventActionHandler();
        }

        const botEvent = (this.event as IInvalidOption);

        if (this.isInvalidOption && isInvalid(botEvent.actionOnErrors)) {
            botEvent.actionOnErrors = getEmptyActionOnErrorsConfig();
            this.initActionOnErrorHandler();
        }

        if (this.event.type === EBotEventType.accuracyEvent) {
            const event = <IAccuracyData>this.event;

            event.insuficientAccuracyUntil ||= this.insuficientMaxValue;
            event.inconclusiveAccuracyUntil ||= this.inconclusiveMaxValue;
        }

        if (!this.shouldEnableExpireMessagesInOrder) {
            this.customerExpire.sendExpireMessagesInOrder = false;
        }
    }

    isAssetsHandlersInitalize: boolean = false
    titleAssetAdderHandler: IAssetAdderHandler;
    assetAdderHandlerArray: {
        [key: string]: IAssetAdderHandler
    } = {};

    generateEnumHandler() {

        const fakeEnum = {};
        let ignore = [];

        const eventTypeMap: Map<string, IOptionBotAsset> = new Map();

        for (let event of this.eventTypes) eventTypeMap.set(event.type, event);

        Object.entries(EBotEventType)
            .filter(([entryKey, entryValue]: [string, EBotEventType]) => eventTypeMap.has(entryValue))
            .forEach(([entryKey, entryValue]: [string, EBotEventType]) => {
                if (eventTypeMap.get(entryValue).disabled) {
                    ignore.push(entryKey);
                }
                fakeEnum[entryKey] = entryValue;
            });
        const current = (isValidRef(this.event.type)) ? this.event.type : undefined;
        this.enumPickerHandler = null;
        this.enumPickerHandler = new EnumPickerHandler(<IEnumPickerHandlerParameter<string>>{
            clientCallback: null,
            client: this,
            ignoreValues: this.isEdit ? [] : ignore,
            mode: EEnumPickerMode.Single,
            translations: botEnumTranslations,
            inputTitle: 'Evento',
            enum: fakeEnum,
            current: current
        });
    }

    validateCommand(event: KeyboardEvent): void {
        if (!this.validateCommandReg.test(this.commandText + event.key)) {
            event.preventDefault();
        }
    }

    get commandText(): string {
        return (<IBotCommandText>this.event).commandText;
    }

    set commandText(command: string) {
        (<IBotCommandText>this.event).commandText = command;
    }

    onSingleEnumSelection(val: EBotEventType): void {
        this.event.type = val;
        this.initCommonHandlers();
    }

    onMultipleEnumSelection(values: EBotEventType[]): void {

    }

    get isInvalidOption(): boolean {
        return this.event?.type === EBotEventType.onInvalidOption;
    }

    get isAccuracy(): boolean {
        return isValidRef(this.event) && this.event.type === EBotEventType.accuracyEvent;
    }

    get isAddressSearch(): boolean {
        return isValidRef(this.event) && this.event.type === EBotEventType.addressSearch;
    }

    get eventAction() {
        return EventActionMap[this.event.type];
    }

    get smartFlowRTFInjection(): ISmartFlowTRFInjection {
        return this.event as unknown as ISmartFlowTRFInjection
    }



    initConfigurableConfirmation(): IConfigurableConfirmationEvent {
        return <IConfigurableConfirmationEvent>{
            yesAction: {
                contentArray: [],
            },
            noAction: {
                contentArray: [],
            },
            warning: {
                contentArray: []
            },
            disambiguationTitle: [],
            eventText: [],
        }
    }

    hasIntent(): boolean {
        return isValidRef(this.handler.idKB);
    }

    searchAddressHandlers: {
        noAddressFound: ISingleContentAssetAdderHandler;
        invalidSearchMode: ISingleContentAssetAdderHandler;
        header: ISingleContentAssetAdderHandler;
    } = {
            noAddressFound: undefined,
            invalidSearchMode: undefined,
            header: undefined
        };

    private buildSearchAddressHandlers(): void {
        const contentArray = this.event.contentArray;
        const handlerForType = (type: EBotContentEvent): ISingleContentAssetAdderHandler => ({
            assetTypeEnabled: [KBAssetType.content],
            content: contentArray.filter(con => con.botContentType === type),
            saveCallBack(content: TContentAssetArray) {
                const first = content[0];
                if (first) {
                    const index = contentArray.findIndex(el => el.idAsset === first.idAsset);
                    if (index > -1) {
                        contentArray.splice(index, 1);
                    }
                    contentArray.push(first);
                } else {
                    const index = contentArray.findIndex(el => el.botContentType === type);
                    if (index > -1) {
                        contentArray.splice(index, 1);
                    }
                }
            },
            contentType: type
        });
        this.searchAddressHandlers.header = handlerForType(EBotContentEvent.header);
        this.searchAddressHandlers.noAddressFound = handlerForType(EBotContentEvent.notFound);
        this.searchAddressHandlers.invalidSearchMode = handlerForType(EBotContentEvent.invalidContent);
    }

    initAssetsHandlers() {
        if (this.isAccuracy) {
            if (!this.isEdit) {
                (<IAccuracyData>this.event).intentNames = {};
            }
            if (this.hasIntent()) this.initIntentNames();
            // this.onConfirmationConfirmHandler = this.initOnConfirmation();
            this.insuficientAccuracyInputNumber = this.initInputNumberHandler(0, 100, (<IAccuracyData>this.event).insuficientAccuracyUntil,
                this.translations.ignoreAccuracyUntil.value, {
                onChangeNumberCallback: (nmb: number) => {
                    (<IAccuracyData>this.event).insuficientAccuracyUntil = nmb;
                }
            })
            this.inconclusiveAccuracyInputNumber = this.initInputNumberHandler(0, 100, (<IAccuracyData>this.event).inconclusiveAccuracyUntil,
                this.translations.confirmAccuracyUntil.value, {
                onChangeNumberCallback: (nmb: number) => {
                    (<IAccuracyData>this.event).inconclusiveAccuracyUntil = nmb;
                }
            })
        }
        if (this.isAddressSearch) {
            this.buildSearchAddressHandlers();
        }

        if (isValidRef(this.event.type) && (this.isReuseForm() || this.isFieldReuseForm() || this.isOnConfirmation || this.isUsefullInsight())) {
            if (!this.isEdit) {
                (<IConfigurableConfirmationEvent>this.event) = {
                    ...this.event,
                    ...this.initConfigurableConfirmation()
                }
            }

            if (this.isUsefullInsight()) {
                this.insighPercentagetNumberInputHandler = this.initInputNumberHandler(0, 100, this.firePercentage
                    , "Digite a porcentagem de vezes que a pergunta ira a parecer"
                    , {
                        onChangeNumberCallback: (nmb: number) => {
                            this.firePercentage = nmb;
                        }
                    }, "%");
                this.yesQuestionSingleAssetAdderHandler = this.initYesQuestionSingleAssetHandler();
                this.noQuestionSingleAssetAdderHandler = this.initNoQuestionSingleAssetHandler();
            }

            if (this.isReuseForm() || this.isFieldReuseForm()) {
                this.reuseFormNumberInputHandler = this.initInputNumberHandler(
                    0, 10000, (<IReuseFormEvent>this.event).hours,
                    "Quantidade de horas até ser necessário preencher novamente o formulário",
                    {
                        onChangeNumberCallback: (nmb: number) => ((<IReuseFormEvent>this.event).hours = nmb)
                    }
                )
            }

            this.onConfirmationConfirmHandler = this.initOnConfirmation()
        }


        if (this.isAccuracy) {
            if (!this.isEdit) {
                (<IAccuracyData>this.event).onInsuficientAccuracy = <IConfigurableConfirmationEvent>{
                    type: EBotEventType.insuficientAccuracy,
                    ...this.initConfigurableConfirmation()
                };
                (<IAccuracyData>this.event).onInconclusiveAccuracy = <IConfigurableConfirmationEvent>{
                    type: EBotEventType.inconclusiveAccuracy,
                    ...this.initConfigurableConfirmation()
                };
                (<IAccuracyData>this.event).onConclusiveAccuracy = <IConfigurableConfirmationEvent>{
                    type: EBotEventType.conclusiveAccuracy,
                    ...this.initConfigurableConfirmation()
                };
            }
            this.accuracyIgnoredConfigurableConfirmHandler = this.initaccuracyIgnoredConfigurableConfirmHandler();
            this.accuracyConfirmConfigurableConfirmHandler = this.initaccuracyConfirmConfigurableConfirmHandler();
            this.accuracySuccessConfigurableConfirmHandler = this.initaccuracySuccessConfigurableConfirmHandler();
        }


        if (this.canShowEventTitle)
            this.titleSingleAssetAdderHandler = this.initTitleSingleAssetHandler();
    }

    initCommonHandlers(): void {
        // this.resetEvent();
        if (this.canShowEventAction()) {
            this.initEventActionHandler();
        }
        if (this.canShowContentArray) {
            this.setHandlerAssets();
        }

        if (this.canShowPrePosAssets) {
            this.handlerPreAssets = this.initAssetsPrePos(EBotContentEvent.preContent);
            this.handlerPosAssets = this.initAssetsPrePos(EBotContentEvent.posContent);
        }

        if (this.hasPreCondition()) {
            this.initPreCondition();
        }
        this.initAssetsHandlers();

        if (this.isInvalidOption) {
            this.initActionOnErrorHandler();
        }

    }
    intentNames: IKBIntentName[] = [];

    actionOnErrorHandler: IActionOnErrorHandler;

    private initActionOnErrorHandler() {
        this.actionOnErrorHandler = {
            actionOnErrorConfig: (this.event as IInvalidOption).actionOnErrors
        }
    }

    filterIntent(intent: IMLCLUIntent) {
        const intentHash = isValidRef((<IAccuracyData>this.event).intentNames) ? (<IAccuracyData>this.event).intentNames : {};
        return intentHash[intent.intentId] ? intentHash[intent.intentId] : ''
    }

    async initIntentNames() {
        const intents: IKBGetInformationResponse = await this.kbSvc.getKBInfo(this.handler.idKB);
        this.intentNames = intents.kb.app.intents.map(intent => {
            return <IKBIntentName>{
                intentID: intent.intentId,
                intentName: intent.intentName,
                intentFriendlyName: this.filterIntent(intent),
            }
        })
    }

    onConfirmationConfirmHandler: IConfigurableConfirmationEventHandler;

    initOnConfirmation(): IConfigurableConfirmationEventHandler {
        return <IConfigurableConfirmationEventHandler>{
            itemLevel: this.handler.itemLevel,
            botType: this.event.type,
            assetTypesEnabled: [KBAssetType.content],
            confEvent: (<IConfigurableConfirmationEvent>this.event),
            saveCallBack: (content) => {
                (<IConfigurableConfirmationEvent>this.event) = {
                    ...(<IConfigurableConfirmationEvent>this.event),
                    ...content
                }
            }
        }
    }

    get shouldEnableExpireMessagesInOrder(): boolean {
        return !this.handler.botEvent.contentArray.some(item => item.randomDisplayControl?.tag);
    }

    accuracyIgnoredConfigurableConfirmHandler: IConfigurableConfirmationEventHandler;

    initaccuracyIgnoredConfigurableConfirmHandler(): IConfigurableConfirmationEventHandler {

        return <IConfigurableConfirmationEventHandler>{
            itemLevel: this.handler.itemLevel,
            botType: (<IAccuracyData>this.event).onInsuficientAccuracy.type,
            assetTypesEnabled: [KBAssetType.content],
            confEvent: (<IAccuracyData>this.event).onInsuficientAccuracy,
            switchAbleBetweenActionAndContent: true,
            hideCustomizationOption: true,
            saveCallBack: (content) => {
                (<IAccuracyData>this.event).onInsuficientAccuracy = {
                    ...(<IAccuracyData>this.event).onInsuficientAccuracy,
                    ...content
                }
            }
        }
    }

    get insuficientAccuracyText(): string {
        return this.confirmationTextSvc.getTextByType(EBotEventType.accuracyEvent)[EOnConfirmationFields.onInsuficientAccuracy]
    }

    get inconclusiveAccuracyText(): string {
        return this.confirmationTextSvc.getTextByType(EBotEventType.accuracyEvent)[EOnConfirmationFields.onInconclusiveAccuracy]
    }

    get conclusiveAccuracyText(): string {
        return this.confirmationTextSvc.getTextByType(EBotEventType.accuracyEvent)[EOnConfirmationFields.onConclusiveAccuracy]
    }

    accuracyConfirmConfigurableConfirmHandler: IConfigurableConfirmationEventHandler;

    initaccuracyConfirmConfigurableConfirmHandler(): IConfigurableConfirmationEventHandler {

        return <IConfigurableConfirmationEventHandler>{
            itemLevel: this.handler.itemLevel,
            botType: (<IAccuracyData>this.event).onInconclusiveAccuracy.type,
            assetTypesEnabled: [KBAssetType.content],
            confEvent: (<IAccuracyData>this.event).onInconclusiveAccuracy,
            showDisambiguationOptions: true,
            showActionPanel: false,
            saveCallBack: (content) => {
                (<IAccuracyData>this.event).onInconclusiveAccuracy = {
                    ...(<IAccuracyData>this.event).onInconclusiveAccuracy,
                    ...content
                }
            }
        }
    }

    accuracySuccessConfigurableConfirmHandler: IConfigurableConfirmationEventHandler;

    initaccuracySuccessConfigurableConfirmHandler(): IConfigurableConfirmationEventHandler {

        return <IConfigurableConfirmationEventHandler>{
            itemLevel: this.handler.itemLevel,
            botType: (<IAccuracyData>this.event).onConclusiveAccuracy.type,
            assetTypesEnabled: [KBAssetType.content],
            confEvent: (<IAccuracyData>this.event).onConclusiveAccuracy,
            hideCustomizationOption: true,
            saveCallBack: (content) => {
                (<IAccuracyData>this.event).onConclusiveAccuracy = {
                    ...(<IAccuracyData>this.event).onConclusiveAccuracy,
                    ...content
                }
            }
        }
    }

    titleSingleAssetAdderHandler: ISingleContentAssetAdderHandler;

    initTitleSingleAssetHandler(): ISingleContentAssetAdderHandler {
        return <ISingleContentAssetAdderHandler>{
            assetTypeEnabled: [KBAssetType.content],
            content: this.event.eventText,
            saveCallBack: (content) => {
                this.event.eventText = content;
            }
        }
    }

    yesQuestionSingleAssetAdderHandler: ISingleContentAssetAdderHandler;

    initYesQuestionSingleAssetHandler(): ISingleContentAssetAdderHandler {
        return <ISingleContentAssetAdderHandler>{
            assetTypeEnabled: [KBAssetType.content],
            content: (<IIsHelpfullAIInsight>this.event).yesAction.contentArray,
            saveCallBack: (content) => {
                (<IIsHelpfullAIInsight>this.event).yesAction = <IBotActionAsset>{
                    contentArray: content,
                    idElement: null,
                    idAsset: getUniqueStringID(10),
                    type: EBotActionType.yesAnswer
                }
            }
        }
    }

    noQuestionSingleAssetAdderHandler: ISingleContentAssetAdderHandler;

    initNoQuestionSingleAssetHandler(): ISingleContentAssetAdderHandler {
        return <ISingleContentAssetAdderHandler>{
            assetTypeEnabled: [KBAssetType.content],
            content: (<IIsHelpfullAIInsight>this.event).noAction.contentArray,
            saveCallBack: (content) => {
                (<IIsHelpfullAIInsight>this.event).noAction = <IBotActionAsset>{
                    contentArray: content,
                    idElement: null,
                    idAsset: getUniqueStringID(10),
                    type: EBotActionType.noAnswer
                }
            }
        }
    }



    initAssetsPrePos(isPos: EBotContentEvent) {
        return {
            assets: this.getAssetsPrePos(isPos),
            assetTypesEnabled: [...Object.values(KBAssetType), KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                this.removeAsset(index);
                const preOrPos = isPos === EBotContentEvent.posContent ? 'handlerPosAssets' : 'handlerPreAssets';
                this[preOrPos].assets = this.getAssetsPrePos(isPos);
                return Promise.resolve(true);
            },
            saveAsset: (newAsset: IBasicAsset, assets: TBasicAssetArray) => {
                (<IContentBasicAsset>newAsset).botContentType = isPos;
                this.setNewAssetContent(newAsset);
                const preOrPos = isPos === EBotContentEvent.posContent ? 'handlerPosAssets' : 'handlerPreAssets';
                this[preOrPos].assets = this.getAssetsPrePos(isPos);
                this.cdr.markForCheck();
                return Promise.resolve(true);
            },
            onChangeAssetsPositions: (assets: TContentAssetArray) => {
                this.event.contentArray = assets;
            },
            alloweedGrouping: this.allowContentAssetGrouping
        }
    }

    getAssetsPrePos(isPos: EBotContentEvent): TBasicAssetArray {
        return this.event.contentArray.filter(asset => isValidAndEqual((<IContentBasicAsset>asset).botContentType, isPos));
    }

    async setHandlerAssets() {
        const contentAssetType: TKAssetTypeArray = this.eventDB[this.event.type].contentAssetTypeAllowed;

        const handlerAssets: IAssetAdderHandler = {
            assets: this.getAssets(),
            assetTypesEnabled: isValidArray(contentAssetType) ? contentAssetType : [...Object.values(KBAssetType), KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                this.removeAsset(index);
                this.handlerAssets.assets = this.getAssets();
                return Promise.resolve(true);
            },
            saveAsset: (newAsset: IBasicAsset, assets: TBasicAssetArray) => {
                this.setNewAssetContent(newAsset);
                this.handlerAssets.assets = this.getAssets();
                return Promise.resolve(true);
            },
            alloweedGrouping: this.allowContentAssetGrouping,
        }


        if (nonNullable(hashAttendanceEvents[this.event.type as TQueueEventSubtype])) {
            handlerAssets.variables ??= {};
            handlerAssets.variables.NonSerializable ??= [];

            const canonicals: IServerLocalCanonical[] = await this.lookupSvc.getBatchNonSerializables(
                this.canonicalSvc.getCanonicalsVariables().map(item => item.idProperty)
            );

            const config = getBotEvent(this.event.type)

            const attendanceVariables = canonicals
                .filter(item => item.globalCanonical)
                .filter(item => nonNullable(config?.safeVariables?.[item.globalCanonical]))
                ;

            attendanceVariables.forEach(item => handlerAssets.variables.NonSerializable.push({ ...nsToVariable(item), isSafe: true }));
        }

        this.handlerAssets = handlerAssets;
    }

    removeAsset(index: number) {
        console.log({ contentArray: this.event.contentArray })
        this.event.contentArray.splice(index, 1);
    }

    setNewAssetContent(asset: IBasicAsset) {
        this.event.contentArray = this.event.contentArray || [];
        const idA = this.event.contentArray.findIndex(a => a.idAsset === asset.idAsset);
        if (idA === -1) {
            this.event.contentArray.push(<IContentBasicAsset>asset);
        } else {
            this.event.contentArray[idA] = <IContentBasicAsset>asset;
        }
    }

    getAssets() {
        return this.event.contentArray || []
    }

    get canShowContentArray() {
        if (isValidRef(this.event.type)) {
            return isValidRef(this.getConfigDB().hasContentAsset);
        } else {
            return false;
        }
    }

    get canShowEventTitle() {
        if (isValidRef(this.event.type)) {
            return isValidRef(this.getConfigDB().showOptionText);
        } else {
            return false;
        }
    }

    private resetEvent(): void {
        this.handler.botEvent = {
            ...typedClone<IBotEvent>(initialEvent),
            idAsset: this.handler.botEvent.idAsset,
            type: this.handler.botEvent.type,
        };
    }

    private initPreCondition(): void {
        this.preConditionSelectHandler = this.getNSSelectHandler();
        if (isInvalidArray(this.preConditions)) {
            Object.values(EEvalulatorType).map(type => {
                this.preConditions.push({
                    type,
                    text: this.getTranslation(gTranslations.bot[type])
                });
            });
        }
    }

    get isEdit(): boolean {
        return this.handler.isEdit;
    }

    get eventTypes(): IOptionBotAssetArray {
        return this.handler.eventTypes;
    }

    get event(): IBotEvent {
        return this.handler.botEvent;
    }

    set event(event: IBotEvent) {
        this.handler.botEvent = event;
    }

    get canShowPrePosAssets(): boolean {
        return isValidRef(this.getConfigDB().hasPreAndPosContentAsset);
    }

    getPreContentTitle(): string {
        
        const config = this.getConfigDB().preAndPosContentTitles;

        return config?.pre || "Pré conteúdo";
    }

    getPosContentTitle(): string {
        const config = this.getConfigDB().preAndPosContentTitles;

        return config?.pos || "Pós conteúdo";
    
    }

    private initEventActionHandler(): void {
        this.eventActionHandler = {
            actionTitle: this.translations.optionAction.value,
            actionTypes: this.eventConfig.actionsAllowed,
            botAction: this.event.eventAction,
            isEventAction: true,
            showConditionalForActions: this.eventConfig.showConditionalForActions
        }
    }

    get eventConfig(): IBotEventTypeConfig {
        return getBotEvent(<EBotEventType>this.event.type);
    }


    canShowEventAction(): boolean {
        return (isValidArray(this.eventConfig.actionsAllowed)
            && !this.isOnConfirmation);
    }

    canShowExpireTime(): boolean {
        return this.eventConfig.hasExpireTime;
    }

    hasPreCondition(): boolean {
        return isValidRef(this.eventConfig.isPreCondition);
    }

    radioChange(event: MatRadioChange): void {
        if (event.value !== EEvalulatorType.checkAcceceTree) {
            this.event.preCondition.idTreeVisited = undefined;
        }
    }

    canShowPreConditionNodeSelect(): boolean {
        return this.event.preCondition.checkType === EEvalulatorType.checkAcceceTree;
    }


    onSaveNSCallback(nsSelected: INonSerializable, nsType: ENonSerializableObjectType) {
        switch (nsType) {
            case ENonSerializableObjectType.bot:
                this.event.preCondition.idTreeVisited = nsSelected ? nsSelected.idNS : null;
                break;
        }
    }

    private getNSSelectHandler(): NSPickerHandler {
        const that = this;
        return new NSPickerHandler({
            nsType: ENonSerializableObjectType.contentGenerator,
            nonSerializablesIds: [this.event.preCondition.idTreeVisited],
            title: this.translations.selectPreConditionItem.value,
            clientCallback: {
                onSaveNSCallback: (nonSerializable: INonSerializable, nsType?: ENonSerializableObjectType) => {
                    that.event.preCondition.idTreeVisited = nonSerializable ? nonSerializable.idNS : null
                }
            },
            genericNonSerializableService: this.generic,
            demandedTag: undefined,
            useNsMatchByCustomerChoice: { allowPropertyValueSelection: true }
        });
    }

    inputNumberHandler: InputNumberHandler;
    initInputNumberHandler(
        min: number,
        max: number,
        initialNumber: number,
        label: string,
        clientCallback: IInputNumberClientCallback,
        suffix: string = "",
        appearance: MatFormFieldAppearance = "fill") {
        return new InputNumberHandler({
            initialNumber,//this.waitTimeSeconds,
            min,
            max,
            clientCallback,
            label,
            suffix,
            appearance
        });
    }

    eventDB: TEventDB = geEventDB();
    get askForWaitTime() {
        return this.eventDB[this.event.type].askForWaitTime;
    }

    get allowContentAssetGrouping() {
        return this.eventDB[this.event.type].allowContentAssetGrouping;
    }

    isDesambiguation(): boolean {
        return this.event.type === EBotEventType.desambiguation;
    }

    get isOnConfirmation(): boolean {
        return this.event.type === EBotEventType.onConfirmation;
    }

    get confirmationEvent(): ITransactionToTransactionConfirmation {
        return <ITransactionToTransactionConfirmation>this.event;
    }

    get expireInMinutes(): number {
        return (<IAgentDoesNotAnwer>this.event).expireInMinutes;
    }

    set expireInMinutes(minutes: number) {
        (<IAgentDoesNotAnwer>this.event).expireInMinutes = minutes;
    }

    get attempts(): number {
        return (<IAgentDoesNotAnwer>this.event).attempts;
    }
    set attempts(attempts: number) {
        (<IAgentDoesNotAnwer>this.event).attempts = attempts;
    }

    isReuseForm(): boolean {
        return this.event.type === EBotEventType.reuseFormInformation;
    }

    isFieldReuseForm(): boolean {
        return this.event.type === EBotEventType.useFormDataToFieldReuse;
    }

    isUsefullInsight(): boolean {
        return this.event.type === EBotEventType.isUsefullInsight
    }

    isCommandText(): boolean {
        return this.event.type === EBotEventType.commandText;
    }

    insighPercentagetNumberInputHandler: InputNumberHandler;
    reuseFormNumberInputHandler: InputNumberHandler;

    get firePercentage(): number {
        return (<IIsHelpfullAIInsight>this.event).firePercentage;
    }

    set firePercentage(firePercentage: number) {
        (<IIsHelpfullAIInsight>this.event).firePercentage = firePercentage;
    }

    private isValidDisambiguationTitle(valueArray: TContentAssetArray | undefined) { 
        return isValidArrayAndRef(valueArray) 
            && valueArray.some(item => isValidTrimmedString(item?.content));
    }

    saveEvent(): void {
        if (this.canShowEventAction() && isValidRef(this.event.eventAction) && !isValidRef(this.event.eventAction.type)) {
            this.snackBarSvc.open("Configure uma ação", "Fechar", { duration: 4000 });
            return;
        }

        if (this.canShowEventTitle && !isValidContentArray(this.event.eventText)) {
            this.snackBarSvc.open("Configure corretamente o conteúdo do evento", "Fechar", { duration: 4000 });
            return;
        }

        const { useActionOnErrors, actionOnErrors } = (this.event as IInvalidOption);
        if (useActionOnErrors && actionOnErrors && actionOnErrors.action?.type === undefined) {
            this.snackBarSvc.open("Adicione uma ação ao executar ação após tentativas de Opção inválida", "Fechar", { duration: 4000 });
            return;
        }

        if (this.isReuseForm() && !this.validateReuseForm()) {
            this.snackBarSvc.open("Configure corretamente o conteúdo das opções do formulário", "Fechar", { duration: 4000 });
            return;
        }

        if(this.isAccuracy && isValidRef((<IAccuracyData>this.event).onInconclusiveAccuracy)) {
            if(!this.isValidDisambiguationTitle((<IAccuracyData>this.event).onInconclusiveAccuracy.disambiguationTitle)) {
                this.snackBarSvc.open("É necessário preencher a mensagem de texto do desambiguador", "Fechar", { duration: 4000 });
                return;
            }
        }

        if (this.isAccuracy && this.hasIntent()) {
            if (isInvalid((<IAccuracyData>this.event).intentNames))
                (<IAccuracyData>this.event).intentNames = {};
            for (const intent of this.intentNames) {
                (<IAccuracyData>this.event).intentNames[intent.intentID] = intent.intentFriendlyName != ''
                    ? intent.intentFriendlyName : intent.intentName
            }
        }
        this.handler.onSaveEventCallback(this.event);

        const assetAdders = genericTypedSuggestions<{ [key in FindKeysWithValueOf<typeof BotEventModalComponent.prototype, IAssetAdderHandler>]: typeof BotEventModalComponent.prototype[key] }>()({
            handlerAssets: undefined,
            handlerPreAssets: undefined,
            handlerPosAssets: undefined,
            titleAssetAdderHandler: undefined,
        });

        for (let assetAdderProperty in assetAdders) {
            const adder: IAssetAdderHandler = this[assetAdderProperty];
            if (isValidRef(adder) && assetAdderWithSlaveGuard(adder)) adder.slave.dispatchSaveAsset();
        }

        console.log({ assetAdders, event: this.event });

        this.dialogRef.close();
    }

    get nouisliderConfig() {
        const { insuficientAccuracyUntil, inconclusiveAccuracyUntil } = <IAccuracyData>this.event

        return {
            start: [insuficientAccuracyUntil, inconclusiveAccuracyUntil],
            tooltips: [true, true],
            margin: this.accuracyMargin,
            connect: [true, true, true],
            pips: {
                mode: 'positions',
                values: [0, 50, 100],
                density: 4,
                stepped: true
            },
            step: 1,
            range: {
                min: this.defaultAccuracyMin,
                max: this.defaultAccuracyMax
            },
            format: {
                to: function (value: number) {
                    return value;
                },
                from: function (value: string) {
                    return Number(value.replace('%', ''));
                }
            }
        }
    }
    get sliderHandler() {
        return {
            options: this.nouisliderConfig
        }
    }

    public setAccuracyConfig([insuficientAccuracyUntil, inconclusiveAccuracyUntil,]: [number, number]) {
        const event = (<IAccuracyData>this.event);

        event.inconclusiveAccuracyUntil = inconclusiveAccuracyUntil;
        event.insuficientAccuracyUntil = insuficientAccuracyUntil;
        console.log('setAccuracyConfig', { insuficientAccuracyUntil, inconclusiveAccuracyUntil });

        // this.noUISlider.set([
        //     event.insuficientAccuracyUntil,
        //     event.inconclusiveAccuracyUntil
        // ]);
    }

    get reuseIfSameChannelAddress(): boolean {
        return (this.event as IReuseFormEvent).reuseIfSameChannelAddress;
    }

    set reuseIfSameChannelAddress(value: boolean) {
        (this.event as IReuseFormEvent).reuseIfSameChannelAddress = value;
    }

    private is(...types: EBotEventType[]): boolean {
        return types.includes(this.event.type);
    }

    onChannelCloseCampaignFilters: Pick<TCampaignActionPickerParameters, 'campaignFilter' | 'actionFilter'> = {
        campaignFilter: (campaign: ICampaignServer) => campaign.campaingActions.some(action => action.allowActionToChannelReopen),
        actionFilter: (campaignAction: ICampaingActionHeader) => campaignAction.allowActionToChannelReopen
    }

    public showChannelChooseReopen(): boolean {
        return this.is(EBotEventType.onChannelClosedOnAgentChat, EBotEventType.customerExpire)
    }

    private validateReuseForm(): boolean {
        const event = this.event as IBasicConfirmationEvent;

        if (isValidContentArray(event.yesAction.contentArray)
            && isValidContentArray(event.noAction.contentArray)
        ) {
            return true;
        }

        return false;
    }

    get isProviderExpire(): boolean {
        return this.event.type === EBotEventType.providerExpire;
    }

    get isCustomerExpire(): boolean {
        return this.event.type === EBotEventType.customerExpire;
    }

    get shouldHideExpireAttemps(): boolean {
        return this.getConfigDB().disableExpireAttemps
    }

    get agentPenalty(): number {
        const event = this.event as IAgentDoesNotAnwer;

        return event.agentPenaltyMS;
    }

    set agentPenalty(v: number) {
        const event = this.event as IAgentDoesNotAnwer;

        event.agentPenaltyMS = v
    }

    agentPenaltyElements: TTimestampElementsConfigs = {
        [ETimestmapPickerElements.Minutes]: {}
    };

    closeModal() {
        this.dialogRef.close();
    }

    canConfigureDynamicIntervals(): boolean {
        return this.getConfigDB().canConfigureDynamicIntervals
    }

    public intervalToAdd: number = 0;

    addAttemptInterval($event: MatChipInputEvent) {
        const event = (this.event as IExpireEvent);
        const value: number = this.intervalToAdd;

        if(!value) {
            this.snackMsgSvc.openWarning('Define um valor para adicionar');
            return;
        }

        if(!isValidArray(event.intervals)) {
            event.intervals = []
        }

        event.intervals.push(value);
        this.intervalToAdd = 0;
    }

    removeAttemptInterval(index: number) {
        (this.event as IExpireEvent).intervals.splice(index, 1);
    }

    onDropDynamicInterval(event: CdkDragDrop<any>) {
        moveItemInArray(
            (this.event as IExpireEvent).intervals,
            event.previousIndex,
            event.currentIndex
        );
    }

    trackByInterval(index: number) {
        return index;
    }

    showLastCall() {
        return !!this.getConfigDB()?.showLastCall && this.dashboardSvc.isDashboard(EDashboardIdentifier.smartflow);
    }

    isCustomerCareContext() {
        return this.dashboardSvc.isDashboard(EDashboardIdentifier.service);
    }

    public lastCallHandlers: ILastCallHandlers = {}

    public idleLastCallType: Record<ECustomerIdleLastCallType, { label: string, isHidden: boolean }> = {
        [ECustomerIdleLastCallType.campaign]: { label: "Campanha", isHidden: true, },
        [ECustomerIdleLastCallType.content]: { label: "Conteúdo", isHidden: false, },
        [ECustomerIdleLastCallType.botAction]: { label: "Ação", isHidden: false, },
        [ECustomerIdleLastCallType.virtualMenu]: { label: "Menu virtual", isHidden: false, },
    }

    ECustomerIdleLastCallType: typeof ECustomerIdleLastCallType = ECustomerIdleLastCallType

    get userIdleExpireActive() {
        return this.customerExpire.idleLastCall?.enabled
    }

    set userIdleExpireActive(value: boolean) {
        this.initLastCallIfNeeded();

        this.customerExpire.idleLastCall.enabled = value;
        this.initLastCallHandlers();
    }

    private initLastCallIfNeeded() {
        if(!isValidRef(this.customerExpire.idleLastCall)) {
            this.customerExpire.idleLastCall = {
                enabled: false,
                tsBefore: hourToMS(1),
                tsBeforeToFinish: minToMS(5),
                callType: ECustomerIdleLastCallType.content,
            }
        }

        if(!this.customerExpire.idleLastCall.tsBeforeToFinish) {
            this.customerExpire.idleLastCall.tsBeforeToFinish = minToMS(5);
        }
    }

    get lastCallCallType() {
        return this.customerExpire.idleLastCall.callType;
    }
    
    set lastCallCallType(value: ECustomerIdleLastCallType) {

        this.customerExpire.idleLastCall.callType = value;
        this.initLastCallHandlers();
    }  

    private initLastCallHandlers() {
        this.initLastCallIfNeeded();
        this.lastCallHandlers = {};

        switch(this.customerExpire.idleLastCall.callType) {
            case ECustomerIdleLastCallType.campaign: {
                if(!isValidRef(this.customerExpire.idleLastCall.camp)) {
                    this.customerExpire.idleLastCall.camp = {
                        idElement: undefined,
                        type: ECampaignExecutionType.traditional,
                    }
                }

                this.lastCallHandlers.campaign = {
                    config: this.customerExpire.idleLastCall.camp,
                }
                break;
            }
            case ECustomerIdleLastCallType.content: {
                if(!isValidArray(this.customerExpire.idleLastCall.content)) {
                    this.customerExpire.idleLastCall.content = [];
                }

                this.getAssetHandler(this.customerExpire.idleLastCall.content).then( content => {
                    this.lastCallHandlers.content = content;
                    this.cdr.detectChanges();
                });
                break;
            }
            case ECustomerIdleLastCallType.botAction: {
                if(!isValidRef(this.customerExpire.idleLastCall.botAction)) {
                    this.customerExpire.idleLastCall.botAction = emptyAsset();
                }

                this.lastCallHandlers.actionHandler = {
                    actionTitle: this.translations.optionAction.value,
                    actionTypes: [EBotActionType.contentGenerator, EBotActionType.goActionTree, EBotActionType.goHuman],
                    botAction: this.customerExpire.idleLastCall.botAction,
                    isEventAction: true,
                }
                break;
            }
            case ECustomerIdleLastCallType.virtualMenu: {
                if(!isValidArray(this.customerExpire.idleLastCall.menuOptions)) {
                    this.customerExpire.idleLastCall.menuOptions = [];
                }

                this.lastCallHandlers.menuTitle = {
                    assetTypeEnabled: [KBAssetType.content],
                    content: this.customerExpire.idleLastCall.menuTitle ? [this.customerExpire.idleLastCall.menuTitle] : [],
                    saveCallBack: ([content]) => {
                        this.customerExpire.idleLastCall.menuTitle = content;
                    }
                }

                break;
            }
        }

    }

    private async getAssetHandler(assets: TContentAssetArray) {

        const contentAssetType: TKAssetTypeArray = this.eventDB[this.event.type].contentAssetTypeAllowed;

        const handlerAssets: IAssetAdderHandler = {
            assets,
            assetTypesEnabled: isValidArray(contentAssetType) ? contentAssetType : [...Object.values(KBAssetType), KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, _assets: IBasicAsset[]) => {
                assets.splice(index, 1);
                
                // this.removeAsset(index);
                this.handlerAssets.assets = assets;
                return Promise.resolve(true);
            },
            saveAsset: (newAsset: IBasicAsset, _assets: TBasicAssetArray) => {
                const idA = assets.findIndex(a => a.idAsset === newAsset.idAsset);

                if (idA === -1) {
                    assets.push(<IContentBasicAsset>newAsset);
                } else {
                    assets[idA] = <IContentBasicAsset>newAsset;
                }

                this.handlerAssets.assets = assets;

                return Promise.resolve(true);
            },
            alloweedGrouping: this.allowContentAssetGrouping,
        }


        if (nonNullable(hashAttendanceEvents[this.event.type as TQueueEventSubtype])) {
            handlerAssets.variables ??= {};
            handlerAssets.variables.NonSerializable ??= [];

            const canonicals: IServerLocalCanonical[] = await this.lookupSvc.getBatchNonSerializables(
                this.canonicalSvc.getCanonicalsVariables().map(item => item.idProperty)
            );

            const config = getBotEvent(this.event.type)

            const attendanceVariables = canonicals
                .filter(item => item.globalCanonical)
                .filter(item => nonNullable(config?.safeVariables?.[item.globalCanonical]))
                ;

            attendanceVariables.forEach(item => handlerAssets.variables.NonSerializable.push({ ...nsToVariable(item), isSafe: true }));
        }

        return handlerAssets;
    }

    
    lastCallElements: TTimestampElementsConfigs = {
        [ETimestmapPickerElements.Hours]: {},
        [ETimestmapPickerElements.Minutes]: {}
    };

    get lastCallTS(): number {
        return this.customerExpire.idleLastCall.tsBefore;
    }

    set lastCallTS(value: number) {
        const currentValue = this.customerExpire.idleLastCall.tsBefore;
        const inMinutes = msToMin(value);
        const inHours = msToHour(value);

        if(inMinutes < 10) {
            this.snackMsgSvc.openWarning('O tempo de antecedência precisa ser no mínimo 10 minutos.');

            queueMicrotask(() => {
                 this.customerExpire.idleLastCall.tsBefore = currentValue;
            });
            return
        } 
        
        // else if(inHours > 5) {
        //     this.snackMsgSvc.openWarning('A distância máxima para o fechamento da janela é de 5 horas.');
            
        //     queueMicrotask(() => {
        //         this.customerExpire.idleLastCall.tsBefore = currentValue;
        //     });
        //     return;
        // }

        this.customerExpire.idleLastCall.tsBefore = value;
    }

    
    get lastCallTSToFinish(): number {
        return this.customerExpire.idleLastCall.tsBeforeToFinish;
    }

    set lastCallTSToFinish(value: number) {
        const currentValue = this.customerExpire.idleLastCall.tsBeforeToFinish;
        const inMinutes = msToMin(value);

        if(inMinutes < 5) {
            this.snackMsgSvc.openWarning('O tempo de antecedência para a finalização deve ser, no mínimo, de 5 minutos.');

            queueMicrotask(() => {
                 this.customerExpire.idleLastCall.tsBeforeToFinish = currentValue;
            });
            return
        } else if (value > this.customerExpire.idleLastCall.tsBefore) {
            this.snackMsgSvc.openWarning('O tempo de antecedência para a finalização não pode ser maior que o prazo para o envio do conteúdo.');

            queueMicrotask(() => {
                 this.customerExpire.idleLastCall.tsBeforeToFinish = currentValue;
            });
        }

        this.customerExpire.idleLastCall.tsBeforeToFinish = value;
    }
}


function assetAdderWithSlaveGuard(assetAdder: IAssetAdderHandler): assetAdder is IAssetAdderHandler {
    return 'slave' in assetAdder;
}
