import { EBIReportViewEnvType } from '@colmeia/core/src/dashboard-control/power-bi/power-bi-dashboard-core.constants';
import { IAnalyticsBiReportItemServer } from '@colmeia/core/src/shared-business-rules/analytics-bi-report-item/analytics-bi-report-item.model';
import { EAppWidgetTypes } from '@colmeia/core/src/shared-business-rules/app-widget/widget.model';
import { EAllSerializable } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { IdDep, TAllNserConnectionTypes } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { IUserApprovalProfileClient, IUserApprovalProfileServer } from '@colmeia/core/src/shared-business-rules/user-approval-profile/user-approval-profile';
import { ICustomUserFunctionParams } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { IUserFunctionRequestPayloadEntity } from '@colmeia/core/src/shared-business-rules/user-function/user-function.interfaces';
import { Minutes } from '@colmeia/core/src/time/time-utl';
import { TGlobalUID } from '../business/constant';
import { FaceCompanyScreenDescriptor } from "../business/face-company";
import { EInitMode } from "../business/security";
import { ESocialMedia } from '../business/social';
import { IAvatarJSON, IGroupJSON, IInteractionJSON, IInteractionJSONArray, IParticipantJSON, TIIChosenAvatars } from '../comm-interfaces/barrel-comm-interfaces';
import { IBasicFullRequestObj, IColmeiaRequestAPI } from '../comm-interfaces/colmeia-services-req-res';
import { ISearchInfo } from '../comm-interfaces/ds-interfaces';
import { TThreadInfoArray } from '../comm-interfaces/interaction-interfaces';
import { IMultimediaObjectJSON } from '../comm-interfaces/multi-media-interfaces';
import { INavigationRules, TArrayID, TExtendedIdentifierArray, TNserUID, TNserUIDArray, TPostgresPrimaryKey } from '../core-constants/types';
import { IDeploymentEnvironmentWorkspace, TBIReportPanel } from '../dashboard-control/business-intelligence/business-intelligence.model';
import { EListNonSerializablesSortOrder, IListNonSerializablesRequest } from '../dashboard-control/dashboard-request-interfaces';
import { IPowerBiAppConfigData, TPowerBIDashboardAdditionalConfigData } from '../dashboard-control/power-bi/power-bi-dashboard-core.model';
import { TIComponentFileInfoArray } from '../multi-media/file-interfaces';
import { IClientVersion } from "../security/version";
import { TSerializableHeaderQuery } from "../serializable/header";
import { ICorporateSearchBody, ICorporateSearchBodyServer, ICorporateSearchHeader, ICorporateSearchHeaderServer } from '../shared-business-rules/corporate-search/corporate-search-model';
import { IFlowInjectionBody, IFlowInjectionBodyServer, IFlowInjectionHeader, IFlowInjectionHeaderServer } from '../shared-business-rules/injection-flow/injection-flow';
import { TComputedInfo } from '../shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ITagableSearch } from '../shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { EFunctionContext, EUserFunctionRunType, IUserFunctionModel, IUserFunctionModelServer } from '../shared-business-rules/user-function/user-function-model';
import { IGeoProcessDirectivers, ILocationCoordinates } from '../tools/geo-util';
import { Merger } from '../tools/utility/types/entities/merger';
import { EApiRequestCorporateSearch, EJobProcess, apiRequestType } from './message-types';
import { IWebHookEvent, TShareTargetArray } from './request-interfaces.models';
import { IResponse, IResponseCursor } from './response-interfaces';
import { IServiceChatInitiatedInteractionJSON } from '../comm-interfaces/service-group-interfaces';
import { EMutationType } from '@colmeia/core/src/shared-business-rules/journaling/journaling-req-res.dto';
import { INSWhatsAppTemplateServer } from '../shared-business-rules/social-media/template-model';
import { IWhatsAppSocialMediaConnectionServer } from '../shared-business-rules/social-media/social-media.model';


export type TArrayGroupSynch = Array<IGroupSynchRequest>;


export interface IUIDInfo {
    deviceID: string;
    browserID?: string;
}
export type UID<DeviceID extends string = string, BrowserID extends string = string> =
    DeviceID | `${DeviceID}-${BrowserID}`
    ;


export interface IGroupSynchRequest {
    idGroup: TGlobalUID;
};


export interface GenericRequest<Type extends string> extends IRequest {
    requestType: Type;
}
export interface GenericResponse<Type extends string> extends IResponse {
    responseType: Type;
}

export interface GenericRequestCursor<Type extends string> extends IRequestCursor {
    requestType: Type;
}
export interface GenericResponseCursor<Type extends string> extends IResponseCursor {
    responseType: Type;
}
export interface IHomologObj {
    id: string,
    isActive: boolean,
    logData?: object
}

export interface IRequest {
    idPlayer: TGlobalUID;
    idCurrentGroup: TGlobalUID;
    idAvatar: TGlobalUID;
    requestType: string;
    dateTime: number;
    timezone: Minutes;
    coordinates: ILocationCoordinates;
    uid: UID;
    browserId: string;
    amountItemsPerPage?: number;
    searchToken?: string;
    cycle?: string;
    idRequest?: string,
    hmlLog?: IHomologObj;
    changeVersion?: TPostgresPrimaryKey;
};

export interface INonSerializableSaveRequest extends IRequest {
    ns: INonSerializableHeader;
}
export interface INonSerializableListSaveRequestData {
    nsType: ENonSerializableObjectType;
    nserList: INonSerializableHeader[];
}
export interface INonSerializableListSaveRequest extends INonSerializableListSaveRequestData, IRequest {

}
export interface INonSerializableDeleteManyRequest extends IRequest {
    nsType: ENonSerializableObjectType;
    nsIdsToDelete: TNserUIDArray;
}

export interface IControlNavigation {
    cursor?: string;
    amountItemsPerPage?: number;
}

export interface WithCursor {
    cursor: string | undefined;
    limit?: number,
}

export interface IRequestCursor extends IRequest {
    cursor: string | undefined;
}

export interface RequestCursor<Type extends string = string> extends Merger<GenericRequest<Type>, IRequestCursor> {

}

export type TMultipleCursor = {
    [idCursor: string]: string
}
export interface IRequestMultipleCursorData {
    multipleCursor: TMultipleCursor;
}
export interface IRequestMultipleCursor extends IRequestMultipleCursorData, IRequest {
}


export interface ILoginTokenRequest extends IRequest {
    isMobile: boolean;
}


export interface ILoginRequest extends ILoginTokenRequest {
    email: string;
    password: string;
    mode: EInitMode;
}


export interface IGetGroupFaceCompanyRequest extends IRequest {
    idGroup: TGlobalUID;
}

export interface IUpsertGroupFaceCompanyRequest extends IRequest {
    idGroup: TGlobalUID;
    descriptor: FaceCompanyScreenDescriptor;
}

export interface ISocialNetworkDataRequest extends IRequest {
    idSocialNetwork: TGlobalUID;
};

export interface IWalletBalanceRequest extends IRequest { };

export interface IInitRequest extends IRequest {
    cacheVersion: number;
}

export interface IGPSTrackerEventsRequest extends IRequest {
    idAvatarTarget: TGlobalUID;
    cursor: string;
    geoDirectives: IGeoProcessDirectivers;
};
export interface ISerializableHeaderInput {
    query: TSerializableHeaderQuery
};
export interface ISerializableHeaderRequest extends IRequest, ISerializableHeaderInput {

};

export interface IConnectionRecoveryRequest extends IRequest {
    idQueue: string;
    groupSynchRequests: TArrayGroupSynch;
};


export interface ITopicRecoverRequest extends IRequest {
    idRecoveryGroup: TGlobalUID;
    idInteraction: TGlobalUID;
};

export interface IFeedbackData {
    idParticipant: TGlobalUID;
    idFeedback: TGlobalUID;
    idInteractionFeedbackType: TGlobalUID;
    idInteractionParent: TGlobalUID;
    idInteractionTypeParent: TGlobalUID;
    threadInfo: TThreadInfoArray;
    idHandShake?: string;
}

export interface IFeedbackCreatorRequest extends IRequest {
    feedbackData: IFeedbackData;
};

export interface IFeedbackGetDetailRequest extends IRequest {
    idFeedback: TGlobalUID;
    cursor: string;
    idInteraction: TGlobalUID;
}

export interface IRunGroupNavigationRequest extends IRequest {
    navigation: INavigationRules;
    cursor: string;
    currentCachedSN: TArrayID;
    pageId?: string;
    isAttendingOnGroup?: boolean
};

export interface IGetGroupCreateEditSupportData extends IRequest {
    idGroup: TGlobalUID;
    isNew: boolean;
};

export interface IGetGroupParticipants extends IRequest {
    idAvatars: TArrayID;
};

export type TSortBy = Array<{
    fieldName: string,
    direction: EListNonSerializablesSortOrder
}>
export interface IPaginatedParams extends WithCursor {
    maxResultsPerPage: number,
    sortBy?: TSortBy
}
export interface IGetAvatarsThatParticipateInAGroupRequest extends IRequest, IPaginatedParams {
    idGroupTarget: string,
}

export interface IAppWidgetRequestData {
    email: string,
    tokenOnce: string,
    widgetType: EAppWidgetTypes,
    parentOrigin: string,
}
export interface IAppWidgetRequest extends IRequest, IAppWidgetRequestData {

}

//socketDataType.participantInteraction
// check InteraciotnAllowed e Role.
export interface ISingleInteractionRequest extends IRequest {
    idGeneralAnswer: TGlobalUID;
    interaction: IInteractionJSON,
    crc: number;
};

export interface IBatchTrackerInteractionRequest extends IRequest {
    interactionArray: IInteractionJSONArray;
};

export type TBatchInsertRequest = IBatchTrackerInteractionRequest;

export interface ISmartshareQueryRequest extends IRequest {
    files: TIComponentFileInfoArray;
};

export type TForgotPassConfirmationID = string;

export interface IForgotPassFirstStep {
    email: string;
}

export interface IForgotPassSecondStep {
    password: string;
    confirmationID: TForgotPassConfirmationID;
}

export interface IForgotPassData extends IForgotPassFirstStep, IForgotPassSecondStep { };

export interface IForgotPassRequest extends IRequest, IForgotPassData { };

export interface IChangePasswordRequest extends IRequest {
    oldPDWHash: string;
    newPWDHash: string;
};

export interface ISignUpFirstStepData {
    email: string;
}

export type TSignUpConfirmationID = string;

export interface ISignUpConfirmationData {
    name: string;
    nickName: string;
    password: string;
    confirmationID: TSignUpConfirmationID;
}

export interface ISignUpData extends ISignUpConfirmationData, ISignUpFirstStepData { };

export interface ISignUpFirstStepRequest extends IRequest, ISignUpFirstStepData { }

export interface ISignUpRequest extends IRequest, ISignUpConfirmationData { };
export interface ISignUpFullRequest extends IRequest, ISignUpData { };

export interface ISignInRequest extends IRequest {
    provider: string
};



//apiRequestType.createParticipant
// checar se o grupo é aberto, se não se há convite ou aprovação
export interface ICreateParticipantRequest extends IRequest {
    participant: IParticipantJSON
};


//apiRequestType.getGroupById
// Response: Grupo -- Checar se é visível ao Player
export interface IGetGroupByIdRequest extends IRequest {
    idGroup: string,
    idAvatar: string
};


//apiRequestType.getGroupById
// Response: Grupo -- Checar se é visível ao Player
export interface IGetGroupAvatars extends IRequest, WithCursor {
    idGroup: string,
    idAvatar: string
};

// pega a todos os avatares on-line em um grupo
export interface IGetAvatarsOnLineOnGroupRequest extends IRequest {
    idGroup: string,
    idAvatar: string
};

export interface ICreateAvatarRequest extends IRequest {
    avatar: IAvatarJSON;
};
export interface IEditAvatarRequest extends ICreateAvatarRequest { };
export interface IEditAvatarNameRequest extends IRequest {
    name: string;
};


//ICheckFileHashAlredyStored

// Request para verificar se há hash do arquivo
export interface IGetSetMultimediaInformation extends IRequest {
    idAvatarPermission: TGlobalUID,
    idGroup: TGlobalUID,
    files: TIComponentFileInfoArray;
    idObjectType: TGlobalUID;
    idIsland?: string;
    primaryID: TGlobalUID;
}

export interface IMultimediaHeader {
    idObjectType: TGlobalUID;
    primaryID: TGlobalUID;
    multimediaObject: IMultimediaObjectJSON;
};

export type TMultimediaHeaderArray = Array<IMultimediaHeader>;

export interface ISetSerializableMultimediaHeader extends IRequest {
    multimediaHeaders: TMultimediaHeaderArray;
};

interface ICommonGroupFields extends IRequest {
    avatar: IAvatarJSON;
    group: IGroupJSON;
}

export interface INewSocialMediaConfiguration {
    hasPartition: boolean;
}

export function initNewSocialMediaConfiguration(): INewSocialMediaConfiguration {
    return {
        hasPartition: true
    }
}

export interface IGroupCreationRequest extends ICommonGroupFields {
    selectedAvatars: TIIChosenAvatars;
    newSocialMedia?: INewSocialMediaConfiguration;
}

export interface IGroupEditRequest extends ICommonGroupFields { };

export interface IManageAvatarOnGroupRequest extends IRequest {
    idGranteeAvatar: TGlobalUID;
    idTargetGroup: TGlobalUID;
}

export interface IManageGroupOnDistributionListRequest extends IRequest {
    idGranteeGroup: TGlobalUID;
    idDistributionListGroup: TGlobalUID;
}

// checar se ele pode criar o grupo no grupo pai
// Retorno OK
//socketDataType.playerConnected
export enum EColmeiaSource {
    app = 'app', webchat = 'webchat'
}

export interface ISocketPlayerConnectedRequest extends IRequest {
    idPlayer: TGlobalUID;
    idDeviceQueue: string;
    lastDeviceQueue: string;
    token: string;
    isMobile: boolean;
    pnToken: string;
    version: IClientVersion;
    source: EColmeiaSource;
};

export interface IGetGroupChildRequest extends IRequest {
    typeSelection: number;
    idGroup: TGlobalUID;
};


/// Groupo-----
//  Igual IGetGroupByIdRequest
//socketDataType.groupSubscription
export interface ISocketGroupSubscriptionRequest extends IRequest {
    participant: IParticipantJSON,
    crc: number
};

// retorno OK
//socketDataType.groupUnsubscription
export interface ISocketGroupUnsubscriptionRequest extends IRequest {
    participant: IParticipantJSON,
    crc: number,
};

// Se for aberto, retorna true:: caso não, checa se o avatar pode entrar
//apiRequestType.subscribeToGroup
export interface IGetGroupDataRequest extends IRequest {
    idGroup: string;
    cursor: string;
    clockTickForSync?: number;
    getInteractions: boolean;
    processChildren: boolean
    pageId?: string;
    isAttendingOnGroupId?: boolean
};


export interface IGetInteractionByIdRequest extends IRequest {
    idInvite: TGlobalUID;
};

export interface IGetInviteeDataToSignUpRequest extends IRequest {
    idInvitee: TGlobalUID,
};

export interface ISearchRequestData {
    searchInfo: ISearchInfo;
}
export interface IFullTextSearchRequest extends IRequest {
    data: {
        textToSearch: string;
    }
}
export interface ISearchRequest extends IRequest, ISearchRequestData {
}
export interface ISearchRemoteEnvRequest extends ISearchRequest, IColmeiaRequestAPI {
    idNSRemoteEnvAuthProvider: IdDep<ENonSerializableObjectType.connection>
}
export interface ISearchCurrentEnvRequest extends ISearchRequestData, IColmeiaRequestAPI {
}
export interface ICheckIfSharedRequest extends IRequest {
    interactionIds: TArrayID;
    participants: TExtendedIdentifierArray;
}

export interface IShareSendRequest extends IRequest {
    whatBeingShared: TShareTargetArray;
    to: TExtendedIdentifierArray;
};

export interface IWebHookEventsRequest extends IRequest {
    events: Array<IWebHookEvent>
}

export interface IUnbindSocialMediaRequest extends IRequest {
    media: ESocialMedia
}



export interface IPaymentMediumRequest extends IRequest {

};

export interface INotificationBilling extends IRequest {
    payload: Object | string;
    query: Object;
    gateway: string;
}

export interface IRawMediumRequest extends IRequest {
    idRequest: TGlobalUID;
}

export interface ICheckoutRequest extends IRequest {
    name: string;
    document: string;
    brand: string;
    mercadopagoToken: string;
    price: string;
    ref: string;
}

export interface DefaultMediumRequest extends IRequest { }

export interface GetMediumRequest extends IRequest {
    idRequest: TGlobalUID;
}

export interface IUserSettingsRequest extends IRequest { }

export interface ISubscriptionsStatusRequest extends IRequest {
    from: TGlobalUID;
    subject?: TGlobalUID;
    subjectObjectType?: TGlobalUID;
}

export interface IUnsubscribeRequest extends IRequest {
    from: TGlobalUID;
    subject: TGlobalUID;
    subjectObjectType: TGlobalUID;
    subType: TGlobalUID;
}

export interface IFacNewsRequest extends IRequest { }

export interface IGetCanonicalDBRequest extends IRequest {
    localIds: string[];
}
export interface IGetAnalyticsReportsInfoProduction {
    viewType: EBIReportViewEnvType.production,
    menuGroupId?: Partial<EAllSerializable>
}
export interface IGetAnalyticsReportsInfoHomolog {
    viewType: EBIReportViewEnvType.homolog,
}

export interface IGetAnalyticsReportsInfoAll {
    viewType: EBIReportViewEnvType.all,
}

export type TIGetAnalyticsReportsInfo = IGetAnalyticsReportsInfoProduction | IGetAnalyticsReportsInfoHomolog | IGetAnalyticsReportsInfoAll
export interface IGetAnalyticsReportsInfoRequest extends IRequest {
    data: TIGetAnalyticsReportsInfo
}

export interface IUpsertAnalyticsReportInfoRequest extends IRequest {
    biReport: IAnalyticsBiReportItemServer
}

export interface IPowerBIDashboardAdditionalConfigRequest extends IRequest {
    data: TPowerBIDashboardAdditionalConfigData
}
export interface IPowerBIDashboardWorkspaceInfoRequest extends IRequest {
    viewType: EBIReportViewEnvType
}
export interface IPowerBIConfigInfoRequest extends IRequest { }

export interface IPowerBIDashboardSetWorkspaceInfoRequest extends IRequest {
    workspaceInfoToSave: IDeploymentEnvironmentWorkspace
}
export interface IPowerBIUpsertReportInfoRequest extends IRequest {
    report: TBIReportPanel
}
export interface IPowerBIDeleteReportInfoRequest extends IRequest {
    data: {
        reportIdToDelete: string
    }
}
export interface IPowerBIDashboardConfigInfoRequest extends IRequest {
    config: IPowerBiAppConfigData
}

export interface IUserFunctionsSaveData {
    ns: IUserFunctionModel
}
export interface IUserFunctionsSaveRequest extends IRequest, IUserFunctionsSaveData {
    saveType: EMutationType
}
export interface IUserFunctionsEditRequest extends IRequest {
    userFunctionNser: IUserFunctionModelServer
}

export interface IUserFunctionsGetAllData {
    onlyColmeiaApproved?: boolean
    userFunctionContext?: EFunctionContext
}

export interface IUserFunctionsGetAllRequest extends IRequest, IUserFunctionsGetAllData, IListNonSerializablesRequest {
}

export interface IUserFunctionCompileRequest extends IRequest, IUserFunctionsSaveData {
    runType: EUserFunctionRunType
}

interface IUserFunctionRun {
    runData: {
        idNS: string,
        userValue: string,
        info: TComputedInfo,
        customFunctionJS?: string,
        fnName?: string
    }
}

export interface IUserFunctionRunRequest extends IUserFunctionRun, IRequest {
    runType: EUserFunctionRunType
}

export interface IUserFunctionRunFromParamsRequest extends IUserFunctionRunRequest {
    runType: EUserFunctionRunType.fromParams
    customParams: ICustomUserFunctionParams
}

export interface IUserFunctionRunFromDatabaseRequest extends IUserFunctionRunRequest {
    runType: EUserFunctionRunType.fromDatabase
    idNS: string
}

export interface IUserFunctionServiceRunRequest extends IUserFunctionRun, IColmeiaRequestAPI {
    serviceId: string
};


export interface IUserFunctionRunRawData {
    data: {
        type: TAllNserConnectionTypes,
        idConnection?: IdDep<ENonSerializableObjectType.connection>,
        idRoute?: IdDep<ENonSerializableObjectType.connectionRoute>,
        requestDataToOverwrite: Partial<IBasicFullRequestObj>
    }
}

export interface IUserFunctionRunRawRequest extends IUserFunctionRunRawData, IRequest {

}

// ICorporateSearch
export interface ICorporateSearchParams {
    idDatabase?: string
    sortByClockTickDesc?: boolean;
    taggable?: ITagableSearch;
}

export interface ICorporateSearchSaveRequestData {
    isForceReprocessDatabase: boolean,
    parent: ICorporateSearchHeader | ICorporateSearchHeaderServer,
    children: ICorporateSearchBody[] | ICorporateSearchBodyServer[]
}

export interface ICorporateSearchSaveRequest extends Partial<INonSerializableListSaveRequestData>, IRequest, ICorporateSearchSaveRequestData {
    nsType: ENonSerializableObjectType.corporateSearchHeader,
    requestType: EApiRequestCorporateSearch.save,
}
export interface ICorporateSearchGetAllRequest extends IRequest, IListNonSerializablesRequest {
    requestType: EApiRequestCorporateSearch.list,
    filterParams: ICorporateSearchParams
}

// RTFParameterized
export interface IRTFParameterizedSaveHeaderRequest extends INonSerializableSaveRequest {
    ns: IFlowInjectionHeaderServer | IFlowInjectionHeader
}

export interface IRTFParameterizedSaveBodyRequest extends INonSerializableSaveRequest {
    ns: IFlowInjectionBodyServer | IFlowInjectionBody
}

export interface IRTFParameterizedGetAllHeaderRequest extends IListNonSerializablesRequest {

}

export interface IRTFParameterizedGetAllBodyRequestData {
    idParent: string
}
export interface IRTFParameterizedGetAllBodyRequest extends IListNonSerializablesRequest {
    searchParams?: IRTFParameterizedGetAllBodyRequestData
}

//

export interface IBlobGetPayloadOutput<T> extends WithCursor {
    entities: T[];
}

//

export interface IUserFunctionsGetPayloadInput extends WithCursor {
    idConversation: string | undefined;
    idCurrentGroup: string;
    target: string;
    amountItemsPerPage?: number;
}

export interface IUserFunctionsGetPayloadOutput extends WithCursor {
    entities: IUserFunctionRequestPayloadEntity[];
}

export interface IUserFunctionsGetPayloadRequest extends GenericRequestCursor<EJobProcess.getPayloads>, IUserFunctionsGetPayloadInput {
}

export interface IUserFunctionsGetPayloadResponse extends GenericResponseCursor<EJobProcess.getPayloads>, IUserFunctionsGetPayloadOutput {
}


export interface IUserApprovalProfileRequest extends IRequest {
    ns: IUserApprovalProfileClient
}

export interface IEditAvatarProfileListRequest extends IRequest {
    userProfilesList: IdDep<ENonSerializableObjectType.userApprovalProfile>[]
    idFromAvatarToBeEdit: TGlobalUID
}
export interface IEditUserGroupProfileRequest extends IRequest {
    updatedUserGroupProfile: IUserApprovalProfileServer
}

export interface IGetOneUserGroupProfileRequest extends IRequest {
    userProfileId: IdDep<ENonSerializableObjectType.userApprovalProfile>
}

export interface IGetWAFlowRequest extends IRequest {
    WABAID: string,
}

export interface IGetOldestRecentInteractionsInfoRequest extends IRequest {
    parentInteractionsIDs: TGlobalUID[];
};

export interface IGetUpdateProviderRequest extends IRequest {
    requestType: typeof apiRequestType.dashboardExtra.marketing.getUpdateProvider;
    connection: IWhatsAppSocialMediaConnectionServer,
    template: INSWhatsAppTemplateServer,
}
