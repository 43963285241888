
<div 
  *ngIf="shouldRenderBorderless"
  class="borderless-header">
  <div class="title-line">
    <h4
      class="main-title"
      style="font-weight: 700;">Mensagens para o usuário > Eventos
    </h4>      
    <mat-menu #availableEventsMenu="matMenu">
      <button
        *ngFor="let eventType of availableEvents"
        mat-menu-item
        (click)="openEventModal(initBotEvent(eventType))"
        [attr.title]="translations[eventType] ? translations[eventType].value : eventType">
        {{ translations[eventType] ? translations[eventType].value : eventType }}
      </button>
    </mat-menu>
    <button
      class="borderless-add-event-button"
      mat-icon-button
      color="primary"
      [matTooltip]="translations.add"
      [matMenuTriggerFor]="availableEventsMenu"
      [disabled]="!availableEvents.length"
      >
        <mat-icon>
          add
        </mat-icon>
    </button>
  </div>
  <hr
    class="custom-hr"
    style="font-weight: 700;">
</div>

<div 
  class="ordenable-list"
  [ngClass]="hideBorderClass">

  <div 
    class="ordenable-item ordenable-header"
    *ngIf="!shouldRenderBorderless">
    <div class="ordenable-item-content">
      <div class="row-title">{{ translations.events }}</div>
    </div>
    <div class="ordenable-item-actions">
      <button
        mat-mini-fab
        color="primary"
        [matTooltip]="translations.add"
        [matMenuTriggerFor]="availableEventsMenu"
        [disabled]="!availableEvents.length"
      >
          <mat-icon>
            add
          </mat-icon>
      </button>
      <mat-menu #availableEventsMenu="matMenu">
        <button
          *ngFor="let eventType of availableEvents"
          mat-menu-item
          (click)="openEventModal(initBotEvent(eventType))"
          [attr.title]="translations[eventType] ? translations[eventType].value : eventType">
          {{ translations[eventType] ? translations[eventType].value : eventType }}
        </button>
      </mat-menu>
    </div>
  </div>

  <ng-container
    *ngIf="!shouldRenderBorderless">
    <div *ngFor="let event of botEvents" class="ordenable-item">
      <div class="ordenable-item-content">
        <div class="row-name">{{ getEventName(event) }}</div>
      </div>
      <div class="ordenable-item-actions">
        <button mat-icon-button color="primary" (click)="openEventModal(event, true)">
          <mat-icon>edit</mat-icon>
        </button>
  
        <button mat-icon-button color="primary" (click)="removeEvent(event)">
          <mat-icon>delete</mat-icon>
        </button>
  
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="shouldRenderBorderless">    
    <div *ngFor="let event of botEvents" 
      class="ordenable-item"
      [ngClass]="hideBorderClass">
        <div class="row-line-wrapper">
          <div class="ordenable-item-content">
            <div class="row-name"><strong>{{ getEventName(event) }}</strong></div>
          </div>
          <div 
            class="ordenable-item-actions"
            [ngClass]="hideBorderClass">
            <button 
              mat-icon-button color="primary" 
              (click)="openEventModal(event, true)"
              class="action-button"
              [ngClass]="hideBorderClass">
              <mat-icon>edit</mat-icon>
            </button>
      
            <button 
              mat-icon-button color="primary" 
              (click)="removeEvent(event)"
              class="action-button remove-event-button"
              [ngClass]="hideBorderClass">
              <mat-icon>delete</mat-icon>
            </button>
      
          </div>           
        </div>

        <div class="visual-feedback-icons-wrapper">
          <div 
            class="row-contents-container nice-scroll">
              <ng-container
                *ngFor="let content of getContentsArray(event)">
                <div 
                  class="row-content-wrapper">
                  <app-bbcode-viewer 
                    *ngIf="content?.content" 
                    [text]="content.content">
                  </app-bbcode-viewer>
                  <div 
                    class="fb-icon inside-message-compare-icon"
                    *ngIf="doesAContentHaveCondition(content)">
                    <mat-icon
                      style="font-size: 15px;">visibility
                    </mat-icon>              
                  </div>  
                </div>
            </ng-container>            
          </div>
        </div>

        <div
            class="vs-fb-icons">
            <div class="icons-wrapper">
              <div class="fb-icon">
                <mat-icon
                  style="font-size: 18px;"
                  [matTooltip]="getEventUfnName(event)"
                  [ngClass]="{ 'disabled-icon': !doesEventHaveUfn(event) }">code
                </mat-icon>              
              </div>
              <div class="fb-icon">
                <mat-icon
                  [ngClass]="{ 'disabled-icon': !doesEventHaveExibitionCondition(event) }"
                  style="font-size: 18px;">visibility
                </mat-icon>              
              </div>
            </div>
          </div>

    </div>
  </ng-container>

</div>