import { EBotContentEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { EBotEventType } from "@colmeia/core/src/shared-business-rules/bot/bot-event-model";
import { INextGenBotServer } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { TReferencedObject, EAdditionalBotObjects, EAdditionalInternalType } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";


export enum EGraphElementInfoBotHandlerTabs {
    actionConfiguration = 'Configuração de ação',
    conditionalDisplay = 'Exibição condicional',
    initialConfiguration = 'Configuração inicial',
    events = 'Eventos',
    variables = 'Variáveis',
    preContent = 'Pre conteúdo',
    posContent = 'Pós conteúdo',
    canonicals = 'Significados'
};


export const botEditTabsIcons: { [key in EGraphElementInfoBotHandlerTabs]: string } = {
    [EGraphElementInfoBotHandlerTabs.actionConfiguration]: 'launch',
    [EGraphElementInfoBotHandlerTabs.conditionalDisplay]: 'visibility',
    [EGraphElementInfoBotHandlerTabs.initialConfiguration]: 'settings',
    [EGraphElementInfoBotHandlerTabs.events]: 'event',
    [EGraphElementInfoBotHandlerTabs.variables]: 'tune',
    [EGraphElementInfoBotHandlerTabs.preContent]: 'border_top',
    [EGraphElementInfoBotHandlerTabs.posContent]: 'border_bottom',
    [EGraphElementInfoBotHandlerTabs.canonicals]: 'spellcheck'
};

export const botGraphElementInfoBotInternalElementToTabHash: { [key in TReferencedObject]?: EGraphElementInfoBotHandlerTabs } = {
    [EBotActionType.goHuman]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotActionType.contentGenerator]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotActionType.goBot]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotActionType.goActionTree]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotContentEvent.preContent]: EGraphElementInfoBotHandlerTabs.preContent,
    [EBotContentEvent.posContent]: EGraphElementInfoBotHandlerTabs.posContent,
    [EBotActionType.goFirstTree]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotActionType.goBack]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotActionType.isEnd]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EBotActionType.LGPD]: EGraphElementInfoBotHandlerTabs.actionConfiguration,
    [EAdditionalBotObjects.menuItemConditionalDisplay]: EGraphElementInfoBotHandlerTabs.conditionalDisplay,
    [EBotEventType.preCondition]: EGraphElementInfoBotHandlerTabs.events,
    [EAdditionalInternalType.AllEvents]: EGraphElementInfoBotHandlerTabs.events,
};

export interface IBotGraphElementInfoBotParameters extends IComponentParameter {
    entity: INextGenBotServer;
    tab?: EGraphElementInfoBotHandlerTabs;
    preSelectedAction?: TReferencedObject;
}

const defaultParameters: Partial<IBotGraphElementInfoBotParameters> = {
    tab: EGraphElementInfoBotHandlerTabs.initialConfiguration,
};

export class BotGraphElementInfoBotHandler extends MainHandler {
    private constructor(parameter: IBotGraphElementInfoBotParameters) {
        super(parameter);
        defaultFields(parameter, defaultParameters);
    }
    public static factory(parameter: IBotGraphElementInfoBotParameters) { return new BotGraphElementInfoBotHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IBotGraphElementInfoBotParameters }
}
