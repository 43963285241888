import { EBotContentEvent } from "../../bot/bot-content-model";
import { EBotEventType } from "../../bot/bot-event-model";
import { ENextGenBotElementType } from "../../bot/bot-model";
import { EBotActionType } from "../../bot/new-bot-action";
import { EBPMType } from "../../BPM/bpm-model";
import { ESubscribedTo } from "../../BPM/common/common-bpm";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { buildStandardConnection, defaultToolbarElement } from "./config-toolbar.other-constants";
import {
    EAdditionalBotObjects,
    EAdditionalInternalType,
    EAdditionalPredicateType,
    EArrowSourcedLineType, EArrowSourcedSpot, EBPMElementMode,
    EConnectorPointShapes, EToolbarGroupType, IBasicToolbarElement, TBPMConnectionStyleMap
} from "./config-toolbar.types";


const gEdgeStylesCommon: TBPMConnectionStyleMap = {
    [EAdditionalPredicateType.Parent]: buildStandardConnection(EConnectorPointShapes.Diamond, "#888888", "#888888"),
    [EBotActionType.contentGenerator]: {
        ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00ba3a", "#00ba3a"),
    },
}

const mktEdgeColor: `#${string}` = '#00cca3';

export const gEdgeStylesCRM: TBPMConnectionStyleMap = {
    ...gEdgeStylesCommon,
    [EAdditionalPredicateType.Parent]: {
        ...buildStandardConnection(EConnectorPointShapes.Square, mktEdgeColor, mktEdgeColor),
        type: EArrowSourcedLineType.Bezier,
    },
    [ESubscribedTo.contentGenerator]: {
        ...buildStandardConnection(EConnectorPointShapes.Diamond, "#777"),
        type: EArrowSourcedLineType.Bezier,
        animated: true,
        reverseAnimation: true,
    }
}
export const gEdgeStylesBOT: TBPMConnectionStyleMap = {
    ...gEdgeStylesCommon
}
export const gEdgeStylesMKT: TBPMConnectionStyleMap = {
    ...gEdgeStylesCommon,
    [EAdditionalPredicateType.Parent]: {
        ...buildStandardConnection(EConnectorPointShapes.Square, mktEdgeColor, mktEdgeColor),
        type: EArrowSourcedLineType.Bezier,
        animated: true,
    }
}
export const gEdgeStylesNSTAI: TBPMConnectionStyleMap = {
    [EBotActionType.goActionTree]: {
        ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00e789", "#00e789"),
        animated: true,
        type: EArrowSourcedLineType.Bezier,
    },
    [EBotActionType.contentGenerator]: {
        ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00ba3a", "#00ba3a"),
        animated: true,
    },
}

export const gToolbarElementsMKT: IBasicToolbarElement[] = [
    {
        referencedObject: ENonSerializableObjectType.bpmMarketingRoot,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        canConnectFrom: {
        },
        canConnectTo: {
            [ENonSerializableObjectType.bpmMarketingListener]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent],
        },
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/root.svg",
        title: "Root"
    },
    {
        referencedObject: ENonSerializableObjectType.bpmMarketingListener,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.bpmMarketingRoot]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
            [ENonSerializableObjectType.bpmMarketingEnd]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingPause]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.contentGenerator]: [ESubscribedTo.contentGenerator],
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent],
            [ENextGenBotElementType.botMenuItem]: [ESubscribedTo.botElement],
        },
        mode: EBPMElementMode.Element,
        canDragOn: [],
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/marketing/mkt-listener.svg",
        title: "Ouvinte de Eventos"
    },
    {
        referencedObject: ENonSerializableObjectType.bpmMarketingPause,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.bpmMarketingEnd]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingPause]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent]
        },
        mode: EBPMElementMode.Element,
        canDragOn: [],
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/marketing/mkt-pause.svg",
        title: "Pausa"
    },
    {
        referencedObject: ENonSerializableObjectType.bpmMarketingAction,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
            [ENonSerializableObjectType.bpmMarketingEnd]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingPause]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent],
        },
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/marketing/mkt-action.svg",
        title: "Ação"
    },

    {
        referencedObject: ENonSerializableObjectType.bpmMarketingEnd,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.bpmMarketingEnd]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingPause]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.bpmMarketingAction]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
        },
        mode: EBPMElementMode.Element,
        canDragOn: [],
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/marketing/mkt-end.svg",
        title: "Fim"
    },

    {
        referencedObject: ENextGenBotElementType.botMenuItem,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        canConnectFrom: {
        },
        canConnectTo: {
            [ENonSerializableObjectType.contentGenerator]: [EBotActionType.contentGenerator]
        },
        canDragOn: [],
        mode: EBPMElementMode.Element,
        // mustShowOnToolbar: false,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/menu-item.svg",
        title: "bot menu item",
        notVisibleInToolbar: true,
    },
    {
        referencedObject: ENonSerializableObjectType.contentGenerator,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        mode: EBPMElementMode.Element,
        canConnectFrom: {
            [ENonSerializableObjectType.bpmMarketingAcknowledge]: [ESubscribedTo.contentGenerator],
            [ENonSerializableObjectType.bpmMarketingListener]: [ESubscribedTo.contentGenerator],
        },
        canConnectTo: {
        },
        canDragOn: [ENonSerializableObjectType.bpmMarketingAcknowledge],
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/content-generator.svg",
        title: "Gerador de conteúdo",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Top,
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00b2ff", "#00b2ff"),
            type: EArrowSourcedLineType.Bezier,
        },
        notVisibleInToolbar: true,
    },

    // Predicados
    {
        referencedObject: EAdditionalPredicateType.Parent,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Bottom,
        targetSpot: EArrowSourcedSpot.Top,
        connectorStyle: buildStandardConnection(EConnectorPointShapes.Diamond, "#525252", "#525252"),
    },

    {
        referencedObject: ESubscribedTo.contentGenerator,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Square, "#00b2ff", "#00b2ff"),
            animated: true,
        },
    },
    {
        referencedObject: ESubscribedTo.botElement,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        sourceSpot: EArrowSourcedSpot.Bottom,
        targetSpot: EArrowSourcedSpot.Left,
        icon: "assets/icons/bpm/parent.svg",
        title: "Inscrição em um elemento de bot",
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00b2ff", "#00b2ff"),
            type: EArrowSourcedLineType.Bezier,
        },
    },
    {
        referencedObject: ESubscribedTo.campaignAction,
        subjectArea: EBPMType.marketing,
        groupType: EToolbarGroupType.MarketingRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        sourceSpot: EArrowSourcedSpot.Bottom,
        targetSpot: EArrowSourcedSpot.Left,
        icon: "assets/icons/bpm/parent.svg",
        title: "Inscrição em um elemento de bot",
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00b2ff", "#00b2ff"),
            type: EArrowSourcedLineType.Bezier,
        },
    },
];

export const gToolbarElementsCRM: IBasicToolbarElement[] = [
    {
        referencedObject: ENonSerializableObjectType.crmServiceConfigRoot,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        canConnectFrom: {
        },
        canConnectTo: {
            [ENonSerializableObjectType.crmServiceConfigAgentEvent]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.crmServiceConfigProcessingNode]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.crmServiceConfigNotification]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.contentGenerator]: [ESubscribedTo.contentGenerator],
        },
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/root.svg",
        title: "Root"
    },
    {
        referencedObject: ENonSerializableObjectType.crmServiceConfigAgentEvent,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.crmServiceConfigRoot]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
            [ENonSerializableObjectType.crmServiceConfigNotification]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.crmServiceConfigAgentEvent]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.crmServiceConfigProcessingNode]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.contentGenerator]: [ESubscribedTo.contentGenerator],
        },
        canDragOn: [ENonSerializableObjectType.crmServiceConfigRoot],
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/agent-events.svg",
        title: "Ação de usuário"
    },
    {
        referencedObject: ENonSerializableObjectType.crmServiceConfigProcessingNode,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.crmServiceConfigRoot]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
            [ENonSerializableObjectType.crmServiceConfigNotification]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.crmServiceConfigAgentEvent]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.contentGenerator]: [ESubscribedTo.contentGenerator],

        },
        canDragOn: [ENonSerializableObjectType.crmServiceConfigRoot],
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/crm-processing-node.svg",
        title: "Processamento de ação"
    },
    {
        referencedObject: ENonSerializableObjectType.crmServiceConfigNotification,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.crmServiceConfigRoot]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.crmServiceConfigProcessingNode]: [EAdditionalPredicateType.Parent],
            [ENonSerializableObjectType.contentGenerator]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
            [ENonSerializableObjectType.contentGenerator]: [ESubscribedTo.contentGenerator],
        },
        canDragOn: [ENonSerializableObjectType.crmServiceConfigRoot],
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/crm-notification.svg",
        title: "Notificações",
        notVisibleInToolbar: true
    },
    {
        referencedObject: ENextGenBotElementType.botMenuItem,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        canConnectFrom: {
            [ENonSerializableObjectType.crmServiceConfigProcessingNode]: [EAdditionalPredicateType.Parent],
        },
        canConnectTo: {
        },
        canDragOn: [],
        mode: EBPMElementMode.Element,
        // mustShowOnToolbar: false,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/menu-item.svg",
        title: "bot menu item",
        notVisibleInToolbar: true
    },
    {
        referencedObject: ENonSerializableObjectType.contentGenerator,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        mode: EBPMElementMode.Element,
        canConnectFrom: {
            [ENonSerializableObjectType.crmServiceConfigRoot]: [ESubscribedTo.contentGenerator],
            [ENonSerializableObjectType.crmServiceConfigProcessingNode]: [ESubscribedTo.contentGenerator],
            [ENonSerializableObjectType.crmServiceConfigAgentEvent]: [ESubscribedTo.contentGenerator],
            [ENonSerializableObjectType.crmServiceConfigNotification]: [ESubscribedTo.contentGenerator],
        },
        canConnectTo: {
            [ENextGenBotElementType.menuContainer]: [EAdditionalPredicateType.Parent],
        },
        canDragOn: [ENextGenBotElementType.root, ENextGenBotElementType.botMenuItem],
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/content-generator.svg",
        title: "Gerador de conteúdo",
        sourceSpot: EArrowSourcedSpot.Top,
        targetSpot: EArrowSourcedSpot.Left,
        connectorStyle: buildStandardConnection(EConnectorPointShapes.Diamond, "#00ba3a", "#00ba3a"),
        notVisibleInToolbar: true
    },
    // Predicados
    {
        referencedObject: EAdditionalPredicateType.Parent,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#525252", "#525252"),
            type: EArrowSourcedLineType.Bezier,
        },
    },
    {
        referencedObject: ESubscribedTo.contentGenerator,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Left,
        targetSpot: EArrowSourcedSpot.Right,
    },
    {
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        referencedObject: ESubscribedTo.botElement,
        width: 18,
        height: 18,
        sourceSpot: EArrowSourcedSpot.Bottom,
        targetSpot: EArrowSourcedSpot.Left,
        icon: "assets/icons/bpm/parent.svg",
        title: "Inscrição em um elemento de bot",
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00b2ff", "#00b2ff"),
            type: EArrowSourcedLineType.Bezier,
        },
    },

    {
        referencedObject: EAdditionalInternalType.Timer,
        subjectArea: EBPMType.crm,
        groupType: EToolbarGroupType.CRMRelated,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        width: 18,
        height: 18,
        sourceSpot: EArrowSourcedSpot.Bottom,
        targetSpot: EArrowSourcedSpot.Left,
        icon: "assets/icons/bpm/marketing/mkt-pause.svg",
        title: "Evento de expiração no objeto",
        notVisibleInToolbar: true,
    },
]

export const gToolbarElementsBot: IBasicToolbarElement[] = [
    // Elementos externos
    {
        ...defaultToolbarElement,
        referencedObject: ENextGenBotElementType.root,
        canConnectFrom: {
            [ENextGenBotElementType.botMenuItem]: [EBotActionType.goBot],
        },
        canConnectTo: {
            [ENextGenBotElementType.root]: [EBotActionType.goBot],
            [ENextGenBotElementType.menuContainer]: [EBotActionType.goActionTree],
            [ENonSerializableObjectType.contentGenerator]: [EBotActionType.contentGenerator],
        },
        canDragOn: [ENextGenBotElementType.root],
        mode: EBPMElementMode.Element,
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/root.svg",
        title: "Root"
    },
    {
        ...defaultToolbarElement,
        referencedObject: ENextGenBotElementType.menuContainer,
        canConnectFrom: {
            [ENextGenBotElementType.root]: [EBotActionType.goActionTree],
            [ENextGenBotElementType.botMenuItem]: [EBotActionType.goActionTree]
        },
        canConnectTo: {
            [ENextGenBotElementType.botMenuItem]: [EAdditionalPredicateType.Parent],
        },
        mode: EBPMElementMode.Element,
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/menu-container.svg",
        title: "Menu Container",
        moveChildrenTogether: true
    },
    {
        ...defaultToolbarElement,
        referencedObject: ENextGenBotElementType.botMenuItem,
        canConnectFrom: {
            [ENextGenBotElementType.menuContainer]: [EAdditionalPredicateType.Parent]
        },
        canConnectTo: {
            [ENextGenBotElementType.root]: [EBotActionType.goBot],
            [ENextGenBotElementType.menuContainer]: [EBotActionType.goActionTree],
            [ENonSerializableObjectType.contentGenerator]: [EBotActionType.contentGenerator],
        },
        mode: EBPMElementMode.Element,
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/menu-item.svg",
        title: "Item de menu",
        isSequenced: true
    },
    {
        ...defaultToolbarElement,
        referencedObject: ENonSerializableObjectType.contentGenerator,
        mode: EBPMElementMode.Element,
        canConnectFrom: {
            [ENextGenBotElementType.root]: [EBotActionType.contentGenerator],
            [ENextGenBotElementType.botMenuItem]: [EBotActionType.contentGenerator],
        },
        canConnectTo: {
            [ENextGenBotElementType.menuContainer]: [EAdditionalPredicateType.Parent],
        },
        canDragOn: [ENextGenBotElementType.root, ENextGenBotElementType.botMenuItem],
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/content-generator.svg",
        title: "Colmeia Forms",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Top,
        connectorStyle: gEdgeStylesBOT[EBotActionType.contentGenerator],
        subLevelConfig: {
            [ENextGenBotElementType.nlpTransaction]: {
                ...defaultToolbarElement,
                referencedObject: ENextGenBotElementType.nlpTransaction,
                mode: EBPMElementMode.Element,
                canDelete: true,
                width: 30,
                height: 30,
                icon: "assets/icons/bpm/ai.svg",
                title: "NLP (IA)",
            },
            [ENextGenBotElementType.formTransaction]: {
                ...defaultToolbarElement,
                referencedObject: ENextGenBotElementType.formTransaction,
                mode: EBPMElementMode.Element,
                canDelete: true,
                width: 30,
                height: 30,
                icon: "assets/icons/bpm/content-generator.svg",
                title: "Colmeia Forms",
            }
        }
    },

    // Elementos internos
    {
        ...defaultToolbarElement,
        referencedObject: EAdditionalInternalType.AllEvents,
        mode: EBPMElementMode.InternalElement,
        canDelete: false,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/events.svg",
        title: "Eventos",
        notVisibleInToolbar: true,
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.goHuman,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-human.svg",
        title: "Encaminhamento a atendente"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.contentGenerator,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.botMenuItem, ENextGenBotElementType.root],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-to-form.svg",
        title: "Gerador de conteúdo"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotContentEvent.preContent,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.root, ENextGenBotElementType.menuContainer],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/pre-content.svg",
        title: "Pré-conteúdo",
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotContentEvent.posContent,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.root, ENextGenBotElementType.menuContainer],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/pos-content.svg",
        title: "Pós-conteúdo"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.goFirstTree,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-first-action.svg",
        title: "Voltar para a primeira questão"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.goBack,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-back-question.svg",
        title: "Volta uma questão"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.isEnd,
        mode: EBPMElementMode.InternalElement,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/is-end.svg",
        title: "Fim do Bot"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.LGPD,
        mode: EBPMElementMode.InternalElement,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/lgpd.svg",
        title: "LGPD"
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotEventType.preCondition,
        mode: EBPMElementMode.InternalElement,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/pre-condition.svg",
        title: "Pre-Condição",
        notVisibleInToolbar: true,
    },

    // Predicados
    {
        ...defaultToolbarElement,
        referencedObject: EAdditionalPredicateType.Parent,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Bottom,
        targetSpot: EArrowSourcedSpot.Left,
        connectorStyle: buildStandardConnection(EConnectorPointShapes.Diamond, "#525252", "#525252"),
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.contentGenerator,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        canDragOn: [ENextGenBotElementType.root],
        connectorStyle: {
            animated: true,
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#333", "#444"),
            type: EArrowSourcedLineType.Bezier,
        },
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.goActionTree,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-action-tree.svg",
        title: "Ir para um próximo menu",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Top,
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00e789", "#00e789"),
            animated: true,
            type: EArrowSourcedLineType.Bezier,
        },
    },
    {
        ...defaultToolbarElement,
        referencedObject: EBotActionType.goBot,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-root-bot.svg",
        title: "Vai para o root de um bot",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Square, "#00b2ff", "#00b2ff"),
            type: EArrowSourcedLineType.Bezier,
            animated: true,
        },
    },
    {
        ...defaultToolbarElement,
        referencedObject: EAdditionalBotObjects.menuItemConditionalDisplay,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [ENextGenBotElementType.botMenuItem],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/conditional-visibility.svg",
        title: "Configura uma exibição condicional"
    }
];

export const gToolbarElementsNestedAI: IBasicToolbarElement[] = [
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: ENextGenBotElementType.nlpTransaction,
        mode: EBPMElementMode.Element,
        canConnectFrom: {
            [ENextGenBotElementType.nlpTransaction]: [EBotActionType.contentGenerator]
        },
        canConnectTo: {
            [ENextGenBotElementType.menuContainer]: [EBotActionType.goActionTree],
            [ENextGenBotElementType.formTransaction]: [EBotActionType.contentGenerator],
            [ENextGenBotElementType.nlpTransaction]: [EBotActionType.contentGenerator]
        },
        canDragOn: [ENextGenBotElementType.nlpTransaction],
        canDelete: false,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/content-generator.svg",
        title: "Gerador de conteúdo NLP",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: ENextGenBotElementType.menuContainer,
        mode: EBPMElementMode.InternalElement,
        canConnectFrom: {
            [ENextGenBotElementType.nlpTransaction]: [EBotActionType.goActionTree],
        },
        canConnectTo: {},
        canDragOn: [ENextGenBotElementType.nlpTransaction],
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/menu-container.svg",
        title: "Menu Container",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        notVisibleInToolbar: true
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: ENextGenBotElementType.formTransaction,
        mode: EBPMElementMode.Element,
        canConnectFrom: {
            [ENextGenBotElementType.nlpTransaction]: [EBotActionType.contentGenerator]
        },
        canConnectTo: {},
        canDragOn: [ENextGenBotElementType.nlpTransaction],
        canDelete: true,
        width: 30,
        height: 30,
        icon: "assets/icons/bpm/content-generator.svg",
        title: "Colmeia Forms",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        notVisibleInToolbar: true
    },
    /* Elementos Internos - Seleção de NSs nas Configurações das intenções */
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotActionType.goActionTree,
        mode: EBPMElementMode.InternalElement,
        canConnectFrom: {
            [ENextGenBotElementType.nlpTransaction]: [EBotActionType.goActionTree],
        },
        canConnectTo: {},
        canDragOn: [ENextGenBotElementType.nlpTransaction],
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/menu-container.svg",
        title: "Menu Container",
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotActionType.contentGenerator, // Representando apenas Colmeia Forms para manter separado os tipos de geradores na toolbar
        mode: EBPMElementMode.InternalElement,
        canConnectFrom: {
            [ENextGenBotElementType.nlpTransaction]: [EBotActionType.contentGenerator],
        },
        canConnectTo: {},
        canDragOn: [ENextGenBotElementType.nlpTransaction],
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/content-generator.svg",
        title: "Colmeia Forms",
        moveChildrenTogether: true,
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotActionType.goHuman,
        mode: EBPMElementMode.InternalElement,
        canConnectFrom: {},
        canConnectTo: {},
        canDragOn: [ENextGenBotElementType.nlpTransaction],
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-human.svg",
        title: "Encaminhamento a atendente"
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotContentEvent.preContent,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/pre-content.svg",
        title: "Pré-conteúdo",
        notVisibleInToolbar: true
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotContentEvent.posContent,
        mode: EBPMElementMode.InternalElement,
        canDelete: true,
        canDragOn: [],
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/pos-content.svg",
        title: "Pós-conteúdo",
        notVisibleInToolbar: true
    },
    /* Predicados (Fallback se o estilo não estiver em gEdgeStylesNSTAI) */
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotActionType.goActionTree,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/go-action-tree.svg",
        title: "Ir para um próximo menu",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        canDragOn: [],
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00e789", "#00e789"),
            animated: true,
            type: EArrowSourcedLineType.Bezier,
        },
    },
    {
        subjectArea: EBPMType.nestedAI,
        groupType: EToolbarGroupType.NestedAIRelated,
        referencedObject: EBotActionType.contentGenerator,
        mode: EBPMElementMode.ArrowSourced,
        canDelete: true,
        width: 18,
        height: 18,
        icon: "assets/icons/bpm/parent.svg",
        title: "Conexão simples",
        sourceSpot: EArrowSourcedSpot.Right,
        targetSpot: EArrowSourcedSpot.Left,
        canDragOn: [],
        connectorStyle: {
            ...buildStandardConnection(EConnectorPointShapes.Diamond, "#00ba3a", "#00ba3a"),
            animated: true,
        },
    },
]
