import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCommonModule } from '@angular/material/core';
import { RouterModule } from "@angular/router";
import { AlertComponent } from 'app/components/alert/alert.component';
import { AnnotationPreviewModalComponent } from 'app/components/annotation/annotation-preview-modal/annotation-preview-modal.component';
import { AnnotationsListComponent } from 'app/components/annotation/annotations-list/annotations-list.component';
import { AnnotationsModalComponent } from 'app/components/annotation/annotations-modal/annotations-modal.component';
import { AnnotationsUpsertComponent } from 'app/components/annotation/annotations-upsert/annotations-upsert.component';
import { ArrowComponent } from 'app/components/arrow/arrow.component';
import { AttendanceBroadcastingCatcherComponent } from 'app/components/attendance-broadcasting-catcher/attendance-broadcasting-catcher.component';
import { InputAuthComponent } from 'app/components/authentication/landing-page/input-auth/input-auth.component';
import { BBCodeViewModalComponent } from 'app/components/bbcode-view-modal/bbcode-view-modal.component';
import { BBCodeViewerComponent } from 'app/components/bbcode-view-modal/bbcode-viewer/bbcode-viewer.component';
import { ButtonAlertComponent } from 'app/components/button-alert/button-alert.component';
import { AttendanceChatChannelClosedComponent } from 'app/components/chat/attendance-chat-channel-closed/attendance-chat-channel-closed.component';
import { GroupOrderSelectorShortcutComponent } from 'app/components/chat/group-order-selector-shortcut/group-order-selector-shortcut.component';
import { ColorThemeBuilderEditDialog } from 'app/components/color-theme-builder/color-theme-builder-edit/color-theme-builder-edit-dialog.component';
import { WebchatPreviewViewer } from 'app/components/color-theme-builder/webchat-preview-viewer/webchat-preview-viewer.component';
import { AttendanceActiveCallAddContactToListComponent } from 'app/components/dashboard/dashboard-attendance-active-call/attendance-active-call-add-contact-to-list/attendance-active-call-add-contact-to-list.component';
import { AttendanceActiveEditCallHistoryComponent } from 'app/components/dashboard/dashboard-attendance-active-call/attendance-active-edit-call/attendance-active-edit-call-history/attendance-active-edit-call-history.component';
import { AttendanceActiveMethodCampaignComponent } from 'app/components/dashboard/dashboard-attendance-active-call/attendance-active-method-campaign/attendance-active-method-campaign.component';
import { AttendanceActiveMethodCustomMessageComponent } from 'app/components/dashboard/dashboard-attendance-active-call/attendance-active-method-custom-message/attendance-active-method-custom-message.component';
import { AttendanceActiveMethodTemplateComponent } from 'app/components/dashboard/dashboard-attendance-active-call/attendance-active-method-template/attendance-active-method-template.component';
import { ColmeiaWindowBarComponent } from 'app/components/dashboard/dashboard-foundation/colmeia-window-bar/colmeia-window-bar.component';
import { ColmeiaWindowTopBarComponent } from 'app/components/dashboard/dashboard-foundation/colmeia-window-top-bar/colmeia-window-top-bar.component';
import { ColmeiaWindowContainer } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-container';
import { GenericDashboardPaginationComponent } from 'app/components/dashboard/dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.component';
import { DashboardMenuComponent } from 'app/components/dashboard/dashboard-menu/dashboard-menu.component';
import { FlatIconComponent } from 'app/components/dashboard/flat-icon/flat-icon.component';
import { UserFunctionsMessageComponent } from 'app/components/dashboard/functions-page/services/user-functions/user-functions-message.component';
import { IconButtonComponent } from 'app/components/dashboard/icon-button/icon-button.component';
import { InputTextVariablesComponent } from 'app/components/dashboard/input-text-variables/input-text-variables.component';
import { JobsListComponent } from 'app/components/dashboard/jobs-page/jobs-list/jobs-list.component';
import { TextIconBoxComponent } from 'app/components/dashboard/knowledge-base/text-icon-box/text-icon-box.component';
import { LoadingSnackbarComponent } from 'app/components/dashboard/loading-snackbar/loading-snackbar.component';
import { MatQuillVarInserterComponent } from 'app/components/dashboard/mat-quill-var-inserter/mat-quill-var-inserter.component';
import { YouTasksDialogComponent } from 'app/components/dashboard/security/you-tasks-edit-dialog.component';
import { YouTasksEditComponent } from 'app/components/dashboard/security/you-tasks-form-solicitation/you-tasks-form-solicitation.component';
import { VarInserterDialogComponent } from 'app/components/dashboard/var-inserter-dialog/var-inserter-dialog.component';
import { InteractivePromptComponent } from 'app/components/dialogs/interactive-prompt/interactive-prompt.component';
import { UpdatedDialogComponent } from 'app/components/dialogs/updated-dialog/updated-dialog.component';
import { AppButtonComponent } from 'app/components/foundation/app-button/app-button.component';
import { AppIconComponent } from 'app/components/foundation/app-icon/app-icon.component';
import { AppTagComponent } from 'app/components/foundation/app-tag/app-tag.component';
import { CmChipListComponent } from 'app/components/foundation/cm-chip-list/cm-chip-list.component';
import { DateHeaderComponent } from 'app/components/foundation/date-header/date-header.component';
import { DatePickerComponent } from 'app/components/foundation/date-picker/date-picker.component';
import { DropdownGlobalComponent } from 'app/components/foundation/dropdown/dropdown-global/dropdown-global.component';
import { ImageSliderComponent } from 'app/components/foundation/image-slider/image-slider.component';
import { TimestampPickerComponent } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { GeneralFormBooleanFieldComponent } from 'app/components/general-form/general-form-boolean-field/general-form-boolean-field.component';
import { GeneralFormDateFieldComponent } from 'app/components/general-form/general-form-date-field/general-form-date-field.component';
import { GeneralFormFieldViewerComponent } from 'app/components/general-form/general-form-field-viewer/general-form-field-viewer.component';
import { GeneralFormImageFieldComponent } from 'app/components/general-form/general-form-image-field/general-form-image-field.component';
import { GeneralFormMultipleChoiceFieldComponent } from 'app/components/general-form/general-form-multiple-choice-field/general-form-multiple-choice-field.component';
import { GeneralFormObjectFieldComponent } from 'app/components/general-form/general-form-object-field/general-form-object-field.component';
import { GeneralFormStringFieldComponent } from 'app/components/general-form/general-form-string-field/general-form-string-field.component';
import { GeneralFormViewerComponent } from 'app/components/general-form/general-form-viewer/general-form-viewer.component';
import { GroupHomeComponent } from 'app/components/group/group-home/group-home.component';
import { HelpTipComponent } from 'app/components/helper-info/help-tip/help-tip.component';
import { HelperInfoComponent } from 'app/components/helper-info/helper-info.component';
import { LoadingComponent } from 'app/components/loading/loading.component';
import { MatIconHoverComponent } from 'app/components/mat-icon-hover/mat-icon-hover.component';
import { ModuleNavMenusComponent } from 'app/components/navigation/module-nav-menus/module-nav-menus.component';
import { ModuleNavComponent } from 'app/components/navigation/module-nav/module-nav.component';
import { CreateGroupDialogComponent } from 'app/components/new-group/dialogs/create-group-dialog/create-group-dialog.component';
import { NonSerializableSelectComponent } from 'app/components/non-serializable-select/non-serializable-select.component';
import { NotificationDialogBoxComponent } from 'app/components/notification-dialog-box/notification-dialog-box.component';
import { NotificationDialogComponent } from 'app/components/notifications-dialog/notification-dialog.component';
import { PrivacyPolicyComponent } from 'app/components/privacy-policy/privacy-policy.component';
import { AvatarInfoEditComponent } from 'app/components/settings/tabs/avatar-info-edit/avatar-info-edit.component';
import { PasswordSettingsComponent } from 'app/components/settings/tabs/password-settings/password-settings.component';
import { StringmometerComponent } from 'app/components/stringmometer/stringmometer.component';
import { TextIconWhiteBoxComponent } from 'app/components/text-icon-white-box/text-icon-white-box.component';
import { TimeWindowPicker } from 'app/components/ticket-time-window-picker/ticket-time-window-picker.component';
import { MatQuill } from "app/components/var-editor/mat-quill/mat-quill";
import { VarEditorTextAreaQuillComponent } from 'app/components/var-editor/var-editor-text-area/var-editor-text-area-quill/var-editor-text-area-quill.component';
import { VarEditorTextAreaComponent } from 'app/components/var-editor/var-editor-text-area/var-editor-text-area.component';
import { VarEditorTextAreaTiptapComponent } from 'app/components/var-editor/var-editor-text-area/var-editor-text-tiptap/var-editor-text-area-tiptap.component';
import { VisibleHorizontalComponent } from 'app/components/visible/visible-horizontal.component';
import { VisibleVerticalComponent } from 'app/components/visible/visible-vertical.component';
import { VisibleComponent } from 'app/components/visible/visible.component';
import { CrmTicketAttrIconViewComponent } from 'app/crm-service-tickets-view/crm-ticket-attr-view/crm-ticket-attr-icon-view.component';
import { CrmTicketAttrViewComponent } from 'app/crm-service-tickets-view/crm-ticket-attr-view/crm-ticket-attr-view.component';
import { TicketsViewNavComponent } from 'app/crm-service-tickets-view/tickets-view-nav/tickets-view-nav.component';
import { AlphanumericDirective } from 'app/directives/alphanumeric.directive';
import { ClickableBadgeDirective } from 'app/directives/clickableBadge.directive';
import { CopyToClipboardDirective } from 'app/directives/copy-to-clipboard.directive';
import { DraggableDirective } from 'app/directives/drag.directive';
import { HighlightElDirective } from 'app/directives/highlight-el.directive';
import { HighlightTextDirective } from 'app/directives/highlight-text.directive';
import { LoadingDirective } from 'app/directives/loading.directive';
import { MatIconSizeDirective } from 'app/directives/mat-icon-size.directive';
import { PopoverDirective } from 'app/directives/popover.directive';
import { SerializableDirective } from 'app/directives/serializable.directive';
import { SwiperDirective } from 'app/directives/swiper.directive';
import { SwitchCasesDirective } from 'app/directives/switch-cases.directive';
import { ColmeiaTemplateDirective } from 'app/directives/template.directive';
import { UILazyloadDirective } from 'app/directives/ui-lazy-load.directive';
import { CaptalizeFirstLetterPipe } from 'app/pipes/captalize-first-letter.pipe';
import { KeyPipe } from 'app/pipes/key.pipe';
import { TranslatePipe } from 'app/pipes/translate.pipe';
import { AttendanceChannelService } from 'app/services/attendace/attendance-channel.service';
import { LookupService } from 'app/services/lookup.service';
import { ProfileAvatarStoreService } from 'app/services/profile-avatar-store.service';
import { EmptyViewComponent } from 'app/views/empty-view/empty-view.component';
import { MainHeaderViewComponent } from 'app/views/main-header-view/main-header-view.component';
import { NewNotificationsPageComponent } from 'app/views/new-notifications-page/new-notifications-page.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { CreditCardInputComponent } from "../../components/billing/inputs/credit-card-input/credit-card-input.component";
import { CarouselArrowComponent } from "../../components/carousel/carousel-arrow/carousel-arrow.component";
import { DotsComponent } from "../../components/carousel/dots/dots.component";
import { UICarouselItemComponent } from "../../components/carousel/ui-carousel-item/ui-carousel-item.component";
import { UICarouselComponent } from "../../components/carousel/ui-carousel/ui-carousel.component";
import { GroupChatLeftNavSideComponent } from "../../components/chat/group-chat-left-side-bar/group-chat-left-side-bar.component";
import { GroupSelectorShortcutComponent } from "../../components/chat/group-selector-shortcut/group-selector-shortcut.component";
import { MultimediaPreviewComponent } from "../../components/chat/message-container/message-instance/multimedia-preview/multimedia-preview.component";
import { VcardPresenterComponent } from "../../components/chat/message-container/message-instance/vcard-presenter/vcard-presenter.component";
import { ChipAutocompleteComponent } from "../../components/chip-autocomplete/chip-autocomplete.component";
import { CmModalComponent } from "../../components/cm-modal/cm-modal.component";
import { BigIconChooseDialogComponent } from "../../components/dialogs/big-icon-choose-dialog/big-icon-choose-dialog.component";
import { ChargeDialogComponent } from "../../components/dialogs/charge-dialog/charge-dialog.component";
import { ColmeiaDialogComponent } from "../../components/dialogs/dialog/dialog.component";
import { GlobalWarningComponent } from "../../components/dialogs/global-warning/global-warning.component";
import { MultimediaPlayerComponent } from "../../components/dialogs/multimedia-player/multimedia-player.component";
import { TransferDialogComponent } from "../../components/dialogs/transfer-dialog/transfer-dialog.component";
import { BreadcrumbComponent } from "../../components/foundation/breadcrumb/breadcrumb.component";
import { CardButtonComponent } from "../../components/foundation/cards/card-button/card-button.component";
import { CardComponent } from "../../components/foundation/cards/card/card.component";
import { CmButtonComponent } from "../../components/foundation/cm-button/cm-button.component";
import { CustomerAvatarComponent } from "../../components/foundation/customer-avatar/customer-avatar.component";
import { EnumPickerComponent } from "../../components/foundation/enum-picker/enum-picker.component";
import { FollowButtonDropdown } from "../../components/foundation/follow-button/follow-button-dropdown.component";
import { HexagonUploaderComponent } from "../../components/foundation/hexagon-uploader/hexagon-uploader.component";
import { HexagonComponent } from "../../components/foundation/hexagon/hexagon.component";
import { CmInputComponent } from "../../components/foundation/inputs/cm-input/cm-input.component";
import { MoreOptionsThreeDotsComponent } from "../../components/foundation/more-options-three-dots/more-options-three-dots.component";
import { MultimediaInstanceUploaderComponent } from "../../components/foundation/multimedia-instance-uploader/multimedia-instance-uploader.component";
import { ParticipantSelectorComponent } from "../../components/foundation/participant-selector/participant-selector.component";
import { SearchComponent } from "../../components/foundation/search/search.component";
import { SpinnerComponent } from "../../components/foundation/spinner/spinner.component";
import { ColmeiaSpinnerDirective } from "../../components/foundation/spinner/spinner.directive";
import { GroupCardItemCarouselComponent } from "../../components/group/group-card-item-carousel/group-card-item-carousel.component";
import { GroupCardItemComponent } from "../../components/group/group-card-item/group-card-item.component";
import { GroupCardListComponent } from "../../components/group/group-card-list/group-card-list.component";
import { GroupMembersComponent } from "../../components/group/group-members/group-members.component";
import { ImageViewerComponent } from "../../components/image-viewer/image-viewer.component";
import { InteractionPreviewerComponent } from "../../components/interaction-previewer/interaction-previewer.component";
import { ColmeiaSpinnerComponent } from "../../components/legacy/spinner/colmeia-spinner.component";
import { ModalBodyComponent } from "../../components/modal-body/modal-body.component";
import { MultimediaInstanceComponent } from "../../components/multimedia-instance/multimedia-instance.component";
import { DropdownMenuComponent } from "../../components/navigation/dropdown-menu/dropdown-menu.component";
import { GroupBackComponent } from "../../components/navigation/group-back/group-back.component";
import { NewMainHeaderComponent } from "../../components/navigation/main-header/new-main-header.component";
import { ToolsContentVisualizationComponent } from "../../components/navigation/tools-content-visualization/tools-content-visualization.component";
import { ToolsListVisualizationComponent } from "../../components/navigation/tools-list-visualization/tools-list-visualization.component";
import { ToolsPageVisualizationComponent } from "../../components/navigation/tools-page-visualization/tools-page-visualization.component";
import { ToolsComponent } from "../../components/navigation/tools/tools.component";
import { SettingsModalComponent } from "../../components/settings/settings-modal/settings-modal.component";
import { LanguageSelectComponent } from "../../components/settings/tabs/language-select/language-select.component";
import { LocationSettingsComponent } from "../../components/settings/tabs/location-settings/location-settings.component";
import { NotificationSettingsComponent } from "../../components/settings/tabs/notification-settings/notification-settings.component";
import { PaymentMediumCreatorComponent } from "../../components/settings/tabs/payment-medium-creator/payment-medium-creator.component";
import { SocialBindComponent } from "../../components/settings/tabs/social-bind/social-bind.component";
import { WalletSettingsComponent } from "../../components/settings/tabs/wallet-settings/wallet-settings.component";
import { ShareComponent } from "../../components/share/share.component";
import { ShareMessageContainerComponent } from "../../components/share/shared-item/shared-item.component";
import { VarEditorBtnComponent } from "../../components/var-editor/var-editor-btn/var-editor-btn.component";
import { VarEditorComponent } from "../../components/var-editor/var-editor/var-editor.component";
import { ImgSecureDownloadPipe } from "../../pipes/img-secure-download.pipe";
import { TrimPipe } from "../../pipes/trim";
import { GlobalErrorHandlerService } from "../../services/global-error-handler.service";
import { MaterialModule } from "../material/material.module";
import { CRMTicketSummaryComponent } from 'app/crm-service-tickets-view/crm-ticket-summary/crm-ticket-summary.component';
import { CRMTicketProtocolComponent } from 'app/crm-service-tickets-view/crm-ticket-protocol-view/crm-ticket-protocol-view.component';
import { ShowTooltipWhenTruncatedDirective } from 'app/directives/show-tooltip-when-truncated.directive';

import { FullCalendarComponent } from 'app/components/foundation/full-calendar/full-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ScrollingPaginatorComponent } from 'app/components/colmeia-pagination/scrolling-paginator/scrolling-paginator.component';

/*
Este módulo deve conter apenas os items (shared) que são usados no módulo basic, os demais, incluir apenas no Shared.
*/


const components: (any[] | Type<any>)[] = [
    CRMTicketProtocolComponent,
    CRMTicketSummaryComponent,
    CrmTicketAttrViewComponent,
    CrmTicketAttrIconViewComponent,
    AppButtonComponent,
    AppTagComponent,
    AppIconComponent,
    AttendanceChatChannelClosedComponent,
    ColmeiaWindowContainer,
    LoadingSnackbarComponent,
    UserFunctionsMessageComponent,
    GroupHomeComponent,
    GroupCardListComponent,
    TranslatePipe,
    StringmometerComponent,
    CaptalizeFirstLetterPipe,
    VisibleVerticalComponent,
    VisibleComponent,
    VisibleHorizontalComponent,
    MatIconSizeDirective,
    ColmeiaTemplateDirective,
    ClickableBadgeDirective,
    SerializableDirective,
    ColmeiaWindowTopBarComponent,
    ColmeiaWindowBarComponent,
    BreadcrumbComponent,
    HexagonComponent,
    ModalBodyComponent,
    CmModalComponent,
    GroupBackComponent,
    GroupChatLeftNavSideComponent,
    GroupSelectorShortcutComponent,
    GroupOrderSelectorShortcutComponent,
    TrimPipe,
    NewMainHeaderComponent,
    ScrollingPaginatorComponent,
    NotificationDialogBoxComponent,
    ToolsComponent,
    ToolsContentVisualizationComponent,
    ToolsListVisualizationComponent,
    ToolsPageVisualizationComponent,
    SearchComponent,
    ShareComponent,
    ImgSecureDownloadPipe,
    KeyPipe,
    ParticipantSelectorComponent,
    ShareMessageContainerComponent,
    CmButtonComponent,
    InteractionPreviewerComponent,
    ChipAutocompleteComponent,
    DropdownMenuComponent,
    FollowButtonDropdown,
    YouTasksDialogComponent,
    YouTasksEditComponent,
    CardComponent,
    CardButtonComponent,
    GroupCardListComponent,
    GroupCardItemComponent,
    GroupCardItemCarouselComponent,
    GroupMembersComponent,
    CarouselArrowComponent,
    MoreOptionsThreeDotsComponent,
    UICarouselComponent,
    UICarouselItemComponent,
    DotsComponent,
    ArrowComponent,
    SwiperDirective,
    UILazyloadDirective,
    LoadingDirective,
    CopyToClipboardDirective,
    DraggableDirective,
    SwitchCasesDirective,
    HighlightElDirective,
    HighlightTextDirective,
    PopoverDirective,
    ShowTooltipWhenTruncatedDirective,

    ColmeiaSpinnerDirective,
    DotsComponent,
    ColmeiaDialogComponent,
    CmInputComponent,
    CreditCardInputComponent,
    ColmeiaSpinnerComponent,
    BigIconChooseDialogComponent,
    ImageSliderComponent,
    ImageViewerComponent,
    GenericDashboardPaginationComponent,

    /* Settings: */
    SettingsModalComponent,
    AvatarInfoEditComponent,
    SocialBindComponent,
    PaymentMediumCreatorComponent,
    LanguageSelectComponent,
    LocationSettingsComponent,
    ChargeDialogComponent,
    TransferDialogComponent,
    WalletSettingsComponent,
    NotificationSettingsComponent,
    PasswordSettingsComponent,

    GlobalWarningComponent,
    InteractivePromptComponent,

    SpinnerComponent,
    MainHeaderViewComponent,
    ModuleNavComponent,
    ModuleNavMenusComponent,
    DashboardMenuComponent,

    MultimediaPlayerComponent,
    MultimediaPreviewComponent,
    MultimediaInstanceComponent,

    AlphanumericDirective,
    IconButtonComponent,
    InputTextVariablesComponent,
    VarEditorBtnComponent,
    VarEditorComponent,
    VarEditorTextAreaComponent,
    VarEditorTextAreaQuillComponent,
    VarEditorTextAreaTiptapComponent,
    BBCodeViewModalComponent,
    BBCodeViewerComponent,
    FlatIconComponent,
    MatIconHoverComponent,

    // Annotation
    AnnotationsModalComponent,
    AnnotationsListComponent,
    AnnotationsUpsertComponent,
    AnnotationPreviewModalComponent,

    // General Form
    GeneralFormViewerComponent,
    GeneralFormFieldViewerComponent,
    GeneralFormStringFieldComponent,
    GeneralFormImageFieldComponent,
    GeneralFormBooleanFieldComponent,
    GeneralFormDateFieldComponent,
    GeneralFormObjectFieldComponent,
    GeneralFormMultipleChoiceFieldComponent,
    HexagonUploaderComponent,
    MultimediaInstanceUploaderComponent,

    TextIconBoxComponent,

    TextIconWhiteBoxComponent,
    NonSerializableSelectComponent,
    EnumPickerComponent,
    CmChipListComponent,
    EmptyViewComponent,
    VcardPresenterComponent,
    JobsListComponent,
    NotificationDialogComponent,
    AlertComponent,
    HelperInfoComponent,
    HelpTipComponent,
    VisibleComponent,
    ButtonAlertComponent,
    DatePickerComponent,
    DateHeaderComponent,
    TimestampPickerComponent,
    CustomerAvatarComponent,

    FullCalendarComponent,

    AttendanceBroadcastingCatcherComponent,

    MatQuill,
    MatQuillVarInserterComponent,

    AttendanceActiveEditCallHistoryComponent,
    AttendanceActiveCallAddContactToListComponent,
    AttendanceActiveMethodTemplateComponent,
    AttendanceActiveMethodCampaignComponent,
    AttendanceActiveMethodCustomMessageComponent,
    NewNotificationsPageComponent,
    VarInserterDialogComponent,

    CreateGroupDialogComponent,
    InputAuthComponent,
    DropdownGlobalComponent,
    TicketsViewNavComponent,
    UpdatedDialogComponent,
    PrivacyPolicyComponent,
    LoadingComponent,
    // Color Theme Builder
    ColorThemeBuilderEditDialog,
    WebchatPreviewViewer,
    TimeWindowPicker,
];

@NgModule({
    declarations: [
        ...components
    ],
    exports: [
        ...components,
        NgxMaskDirective,
        NgxMaskPipe,
        CommonModule,
        MaterialModule,
        QuillModule,
        OverlayModule,
    ],
    imports: [
        RouterModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        OverlayModule,
        PortalModule,
        MatCommonModule,
        NgxMaskDirective,
        NgxMaskPipe,
        FullCalendarModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'strike'],        // toggled buttons
                    ['code-block'],
                ]
            }
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        ProfileAvatarStoreService,
        LookupService,
        provideNgxMask(),
        AttendanceChannelService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedEssentialsModule { }
